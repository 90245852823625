import React from "react";
import SPLHTile from "./LabourStatsTiles/SPCH";
import LabourCostTile from "./LabourStatsTiles/LabourCost";
import AHRTile from "./LabourStatsTiles/AHR";
import ForecastTile from "./LabourStatsTiles/ForecastCompare";
import LabourForecastTile from "./LabourStatsTiles/LabourForecast";
import LabourPercentageTile from "./LabourStatsTiles/LabourPercentage";
import useWindowSize from "../../../hooks/useWindowSize";
import "./LabourStats.css";

const LabourStats = () => {
  const size = useWindowSize();

  return (
    <div className="l-s-container">
      <div className="l-s-row-one">
        <LabourCostTile />
        {size.width > 480 && <ForecastTile />} {/* Conditionally render based on screen width */}
        <LabourForecastTile />
      </div>
      <div className="l-s-row-two">
        <LabourPercentageTile />
        <AHRTile />
        <SPLHTile />
      </div>
    </div>
  );
};

export default LabourStats;
