import React, { useState, useEffect, useContext } from 'react';
import { db, collection, query, where, getDocs } from '../../../firebase';
import { SiteContext } from '../../../context/SiteContext';
import { useData } from '../../../context/LabourDataContext';
import { format } from "date-fns";
import "./TimesheetTable.css";

const ShiftsTable = () => {
  const { departments } = useData();
  const { selectedDate, selectedSite } = useContext(SiteContext);
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    const fetchShifts = async () => {
      if (!selectedSite || !selectedDate) {
        setShifts([]);
        return;
      }
      try {
        const date = format(new Date(selectedDate), "yyyy-MM-dd");
        const shiftQueryRef = collection(db, "shifts");
        const shiftQuery = query(
          shiftQueryRef,
          where("date", "==", date),
          where("department_id", "in", departments.departments)
        );
        const shiftsSnapshot = await getDocs(shiftQuery);

        const shiftsData = shiftsSnapshot.docs.map(doc => doc.data());
        setShifts(shiftsData);
      } catch (error) {
        console.error("Error fetching shifts:", error);
      }
    };

    fetchShifts();
  }, [selectedDate, selectedSite, departments]);
  
  const convertUnixToLocalTime = (unixTime) => {
    const date = new Date(unixTime * 1000); // Convert Unix time to milliseconds
    return date.toLocaleTimeString(); // Convert to local time string
  };

  return (
    <div className="shifts-table">
      <div className="table-header-container">
        <div className="table-header">
          <div className="header-cell">Name</div>
          <div className="header-cell">Date</div>
          <div className="header-cell">Cost</div>
          <div className="header-cell">Length</div>
          <div className="header-cell">Start Time</div>
          <div className="header-cell">Finish Time</div>
        </div>
      </div>
      <div className="table-rows-container">
        {shifts.map((shift, index) => (
          <div className="table-row" key={index}>
            <div className="table-cell">{shift.user_name}</div>
            <div className="table-cell">{shift.date}</div>
            <div className="table-cell">${shift.cost.toFixed(2)}</div>
            <div className="table-cell">{shift.shiftLengthInHours.toFixed(2)} hr</div>
            <div className="table-cell">{convertUnixToLocalTime(shift.start)}</div>
            <div className="table-cell">{convertUnixToLocalTime(shift.finish)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShiftsTable;