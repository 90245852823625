import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../../firebase";
import { collection, addDoc } from "firebase/firestore";
import "../AddStoreModal/AddStoreModal.css"

const AddCompanyModal = ({ onClose }) => {
  const [companyName, setCompanyName] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "companies"), {
        name: companyName
      });
      navigate("/dashboard");
    } catch (error) {
      console.error("Error adding company: ", error);
    }
  };

  return (
    <div className="ab-modal-backdrop" onClick={onClose}>
      <div className="ab-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="ab-title">Add a new company</div>
        <form onSubmit={handleSubmit}>
          <div className="ab-form-group">
            <label>Company Name</label>
            <input
              type="text"
              placeholder="ex: HospoSense Pty Ltd"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </div>
          <div className="ab-button-group">
            <button type="button" className="ab-modal-close" onClick={onClose}>
              Close
            </button>
            <button type="submit" className="ab-submit-button">
              ADD COMPANY
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCompanyModal;
