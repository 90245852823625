// src/integrations-page/integrations-page.js
import React, { useState } from "react";
import "../main-dash-components/Dashboard/Dashboard.css";
import "./integrations-page.css"; // Import the new CSS file for the grid
import Navbar from "../global-components/navbar/navbar";
import Hamburger from "hamburger-react";
import SiteSelector from "../main-dash-components/SiteSelector/SiteSelector";
import KountaAuth from "./integration-widgets/lso-oauth/lso-oauth";
import XeroAuth from "./integration-widgets/xero-oauth/xero-auth";
import DeputyAuth from "./integration-widgets/deputy-oauth/deputy-auth";
import AbacusAuth from "./integration-widgets/abacus-auth/abacus-auth";
import TandaAuth from "./integration-widgets/tanda-oauth/tanda-oauth";

const Integrations = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div className="main-container">
      <Navbar isMenuOpen={isMenuOpen} onMenuToggle={toggleMenu} />
      <div className="main-dashboard">
        <Navbar />
        <div className="header">
          <div className="selectors-row">
            <Hamburger toggled={isMenuOpen} toggle={toggleMenu} />
            <SiteSelector />
          </div>
        </div>
        <div className="integrations-grid">
          <KountaAuth />
          <AbacusAuth />
          <XeroAuth />
          <DeputyAuth />
          <TandaAuth />
        </div>
      </div>
    </div>
  );
};

export default Integrations;
