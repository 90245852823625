// LabourCost.jsx
import React from "react";
import { useData } from "../../../../context/LabourDataContext";
import "./LabourStatsTiles.css";

const LabourPercentageTile = () => {
  const { labourCostTotal } = useData();
  const { salesTotal } = useData();

  const labourCalc = labourCostTotal / salesTotal;
  const labourPerc = labourCalc * 100;


  return (
    <div className="labour-tile">
      <h3>Labour Percentage</h3>
      <p>{labourCostTotal !== null ? `${labourPerc.toFixed(2)}%` : "Loading..."}</p>
    </div>
  );
};

export default LabourPercentageTile;
