// SPCH.jsx
import React from "react";
import { useData } from "../../../../context/LabourDataContext";
import "./LabourStatsTiles.css";

const SPCHTile = () => {
  const { salesTotal, totalLabourHours } = useData();

  const calculateSalesPerLabourHour = () => {
    if (totalLabourHours === 0) return 0;
    return (salesTotal / totalLabourHours).toFixed(2);
  };

  return (
    <div className="labour-tile">
      <h3>Sales per Labour Hour</h3>
      <p>{totalLabourHours !== null ? `$${calculateSalesPerLabourHour()}` : "Loading..."}</p>
    </div>
  );
};

export default SPCHTile;
