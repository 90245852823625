import React, { useState, useContext, useEffect } from "react";
import "../../integration-widgets/integration-tile.css";
import "../abacus-auth/abacus-auth.css";
import { db, doc, getDoc } from "../../../firebase";
import { SiteContext } from "../../../context/SiteContext";
import { setDoc, updateDoc } from "firebase/firestore";
import { format, sub } from "date-fns";

const AbacusAuth = () => {
  const { selectedSite } = useContext(SiteContext);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const checkConnectionStatus = async () => {
      if (selectedSite) {
        const docRef = doc(
          db,
          "stores",
          selectedSite,
          "IntegrationInfo",
          "AbacusIntegration"
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsConnected(docSnap.data().abacusConnected);
        }
      }
    };

    checkConnectionStatus();
  }, [selectedSite]);

  const title = "Abacus";
  const description = "Abacus: Comprehensive Point of Sale Solution";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [prevApiKey, setPrevApiKey] = useState("");

  const handleAuth = async () => {
    if (selectedSite) {
      const apiKeyRouting = doc(db, "abacus-api-keys", selectedSite);
      const docSnap = await getDoc(apiKeyRouting);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setApiKey(data.apiKey || "");
        setPrevApiKey(data.apiKey || ""); // Save the previous apiKey
      } else {
        setApiKey("");
        setPrevApiKey(""); // No previous apiKey
      }
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const mappings = { apiKey: apiKey, storeName: selectedSite };
    const apiKeyRouting = doc(db, "abacus-api-keys", selectedSite);
    await setDoc(apiKeyRouting, mappings);

    const connectedStatusMappings = {
      abacusConnected: true,
      storeName: selectedSite,
    };
    const connectedRouting = doc(
      db,
      "stores",
      selectedSite,
      "IntegrationInfo",
      "AbacusIntegration"
    );

    await setDoc(connectedRouting, connectedStatusMappings);

    const locationDataPath = `stores/${selectedSite}`;
    const locationRouting = doc(db, locationDataPath);
    await updateDoc(locationRouting, { POSID: selectedSite });

    // Check if apiKey has been updated
    if (apiKey !== prevApiKey) {
      const auth = `ApiKey ${apiKey}`;

      const lastUpdated = format(
        sub(new Date(), { days: 30 }),
        "yyyy-MM-dd'T'HH:mm:ss.SS'Z'"
      );

      const createdTo = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SS'Z'");

      await fetch(
        "https://australia-southeast1-hospo-sense.cloudfunctions.net/abacus-product-sync-2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: selectedSite,
            auth: auth,
          }),
        }
      );

      fetch(
        "https://australia-southeast1-hospo-sense.cloudfunctions.net/abacus-sales-sync-1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: selectedSite,
            auth: auth,
            lastUpdated: lastUpdated,
            createdTo: createdTo,
          }),
        }
      );
    }

    closeModal();
  };

  const copyWebhookURL = () => {
    const webhookURL = `https://australia-southeast1-hospo-sense.cloudfunctions.net/abacus-sales-intake-q?storename=${selectedSite}`;
    navigator.clipboard.writeText(webhookURL).then(() => {});
  };

  const [mainDescription, subDescription] = description.split(":");

  return (
    <div className="integration-tile">
      <div className="int-header">
        <img className="logo" src="/icons/abacuslogo.png" alt="Logo" />
        <div className="title">{title}</div>
      </div>
      <div className="description">
        {mainDescription}:
        <br />
        {subDescription}
      </div>
      <div className="status">
        <div className="status-indicator">
          <div className={`ellipse ${isConnected ? "connected" : ""}`} />
          <div className="status-text">
            {isConnected ? "Connected" : "Not Connected"}
          </div>
        </div>
        <button className="connect-button" onClick={handleAuth}>
          Show me how
        </button>
      </div>

      {isModalOpen && (
        <div className="aba-modal-backdrop">
          <div className="aba-modal">
            <div className="aba-modal-content">
              <h2>{title} Integration Steps</h2>
              <p>Steps to connect:</p>
              <ol>
                <li>
                  Load into the&nbsp;
                  <a
                    href="https://app.abacus.co/AddOn/ApiConfig"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abacus API Settings Page
                  </a>
                </li>
                <li>
                  Add a New Sales (Detailed) Webhook, using the URL below.
                </li>
                <li>Generate an API Key</li>
                <li>
                  Enter the API Key and storeName you set in the boxes below
                </li>
              </ol>
              <div className="copy-button-container">
                <button className="aba-copy-button" onClick={copyWebhookURL}>
                  Copy Webhook URL
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="aba-form-group">
                  <label>API Key:</label>
                  <input
                    type="text"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    required
                  />
                </div>
                <div className="aba-button-group">
                  <button
                    type="button"
                    className="aba-modal-close"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="aba-submit-button">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AbacusAuth;
