import React, { useState } from 'react';
import './AddManagement.css';
import AddStoreModal from '../AddStoreModal/AddStoreModal';
import AddCompanyModal from '../AddCompanyModal/AddCompanyModal';
import AddProfileModal from '../AddProfileModal/AddProfile';

const AddManagement = () => {
  const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const openStoreModal = () => setIsStoreModalOpen(true);
  const closeStoreModal = () => setIsStoreModalOpen(false);

  const openCompanyModal = () => setIsCompanyModalOpen(true);
  const closeCompanyModal = () => setIsCompanyModalOpen(false);

  const openProfileModal = () => setIsProfileModalOpen(true);
  const closeProfileModal = () => setIsProfileModalOpen(false);

  return (
    <div className="add-management-container">
      <button className="user-management-btn" onClick={openProfileModal}>
        <img src="/images/addUser.svg" alt="Add New User" className="btn-icon" />
        <span className="btn-text">Add New User</span>
      </button>
      <button className="user-management-btn" onClick={openStoreModal}>
        <img src="/images/addStore.svg" alt="Add New Store" className="btn-icon" />
        <span className="btn-text">Add New Store</span>
      </button>
      <button className="user-management-btn" onClick={openCompanyModal}>
        <img src="/images/addCompany.svg" alt="Add New Company" className="btn-icon" />
        <span className="btn-text">Add New Company</span>
      </button>

      {isStoreModalOpen && (
        <AddStoreModal onClose={closeStoreModal} />
      )}
      {isCompanyModalOpen && (
        <AddCompanyModal onClose={closeCompanyModal} />
      )}
      {isProfileModalOpen && (
        <AddProfileModal onClose={closeProfileModal} />
      )}
    </div>
  );
};

export default AddManagement;
