// LabourCost.jsx
import React from "react";
import { useData } from "../../../../context/LabourDataContext";
import "./LabourStatsTiles.css";

const ForecastTile = () => {
  const { labourCostTotal } = useData();

  const accRost = labourCostTotal / labourCostTotal * 100;

  return (
    <div className="labour-tile">
      <h3>Accuracy to Roster</h3>
      <p>{accRost !== null ? `${accRost.toFixed(2)}%` : "Loading..."}</p>
    </div>
  );
};

export default ForecastTile;
