import React, { useState, useEffect, useContext } from "react";
import { db, doc, getDoc } from "../../../firebase";
import { SiteContext } from "../../../context/SiteContext";
import { format } from "date-fns";
import "./LabourCostGraph.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LabourUsageGraph = () => {
  const { selectedSite, selectedDate } = useContext(SiteContext);
  const [salesLabourData, setSalesLabourData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSalesLabourData = async () => {
      if (!selectedSite || !selectedDate) {
        setSalesLabourData([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const date = format(selectedDate, "dd-MM-yyyy");
        const storeDocRef = doc(db, `stores/${selectedSite}`);
        const storeDocSnap = await getDoc(storeDocRef);

        if (!storeDocSnap.exists()) {
          setSalesLabourData([]);
          setLoading(false);
          return;
        }

        const taxFilter = storeDocSnap.data()?.includeTax;
        const hoursDocRef = doc(
          db,
          `stores/${selectedSite}/summary/${date}/Labour/Hours`
        );
        const salesDocRef = doc(
          db,
          `stores/${selectedSite}/summary/${date}/Hours/${
            taxFilter ? "hourlySales" : "hourlyExTaxSales"
          }`
        );

        const [hoursDocSnap, salesDocSnap] = await Promise.all([
          getDoc(hoursDocRef),
          getDoc(salesDocRef),
        ]);

        if (!hoursDocSnap.exists() || !salesDocSnap.exists()) {
          setSalesLabourData([]);
          setLoading(false);
          return;
        }

        const hoursData = hoursDocSnap.data();
        const salesData = salesDocSnap.data();

        const normalizeHourKey = (hour) => hour.toString().padStart(2, "0");

        const combinedData = Object.keys(hoursData).map((hour) => {
          const normalizedHour = normalizeHourKey(hour);
          const hoursWorked = hoursData[hour];
          const sales = salesData[normalizedHour] || 0; // Handle missing sales data
          const salesPerHour = hoursWorked > 0 ? sales / hoursWorked : 0;

          return {
            hour: parseInt(hour, 10),
            salesPerHour,
          };
        });

        setSalesLabourData(combinedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching sales and labour data:", error);
        setSalesLabourData([]);
        setLoading(false);
      }
    };

    fetchSalesLabourData();
  }, [selectedSite, selectedDate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const data = {
    labels: salesLabourData.map((item) => item.hour),
    datasets: [
      {
        label: "Sales per Labour Hour",
        data: salesLabourData.map((item) => item.salesPerHour.toFixed(2)),
        backgroundColor: "#00a6fb",
        borderRadius: 10,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          maxTicksLimit: 5,
        },
      },
      x: {
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
  };

  return (
    <div className="labour-usage-graph">
      <Bar type="bar" data={data} options={options} />
    </div>
  );
};

export default LabourUsageGraph;
