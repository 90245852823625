import React, { useState, useEffect, useContext } from "react";
import { db, getDoc, doc } from "../../../firebase";
import { SiteContext } from "../../../context/SiteContext";
import { format, eachDayOfInterval } from "date-fns";
import { Bar } from "react-chartjs-2";
import "./PremiumGraph.css";

const SalesRange = () => {
  const { selectedSite, selectedDateRange } = useContext(SiteContext);
  const [rangedSales, setRangedSales] = useState({
    totalAmount: 0,
    data: [],
  });
  const [taxFilter, setTaxFilter] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRangedSalesData = async () => {
      if (!selectedSite || !selectedDateRange) {
        setLoading(false);
        return;
      }

      try {
        const taxFilterRef = await getDoc(doc(db, `stores/${selectedSite}`));
        const taxFilterDoc = taxFilterRef.data()?.includeTax;
        setTaxFilter(taxFilterDoc);
        const startDate = selectedDateRange[0];
        const endDate = selectedDateRange[1];

        // Generate all dates within the selected date range
        const dates = eachDayOfInterval({
          start: startDate,
          end: endDate,
        });

        // Initialize an array to hold the sales data
        const salesData = [];

        // Loop through each date and fetch the sales data
        for (let date of dates) {
          const formattedDate = format(date, "dd-MM-yyyy");
          const docRef = doc(db, `stores/${selectedSite}/summary/${formattedDate}`);
          const docSnap = await getDoc(docRef);
          const reportDate = format(date, "dd-MMM");

          if (docSnap.exists()) {
            const salesValue = taxFilter
              ? docSnap.data().totalSales || 0
              : docSnap.data().totalSalesExTax || 0;

            salesData.push({ date: reportDate, sales: salesValue });
          } else {
            salesData.push({ date: reportDate, sales: 0 });
          }
        }

        // Calculate the total sales amount
        const totalAmount = salesData.reduce((acc, curr) => acc + curr.sales, 0);

        // Update the state with the fetched data
        setRangedSales({
          totalAmount,
          data: salesData,
        });

      } catch (error) {
        console.error("Error fetching weekly sales data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRangedSalesData();
  }, [selectedSite, selectedDateRange, taxFilter]);

  const chartData = {
    labels: rangedSales.data.map((item) => item.date),
    datasets: [
      {
        label: "Sales",
        data: rangedSales.data.map((item) => item.sales),
        backgroundColor: "#00a6fb",
        borderRadius: 10,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          maxTicksLimit: 5,
        },
      },
      x: {
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="Premium-Graph">
          <Bar data={chartData} options={options} />
        </div>
      )}
    </div>
  );
};

export default SalesRange;
