import React, { createContext, useContext, useEffect, useState } from "react";
import { db, doc, getDoc } from "../firebase";
import { format, startOfWeek, endOfWeek, addDays, subDays } from "date-fns";
import { SiteContext } from "./SiteContext";

const SalesContext = createContext();

export const SalesProvider = ({ children }) => {
  const { selectedSite, selectedDate } = useContext(SiteContext);
  const [dailySales, setDailySales] = useState({
    amount: 0,
    data: [],
  });
  const [previousWeekSales, setPreviousWeekSales] = useState({
    amount: 0,
    data: [],
  });
  const [weeklySales, setWeeklySales] = useState({
    total: 0,
    data: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSalesData = async (date) => {
      if (!selectedSite) {
        return { amount: 0, data: Array(8).fill({ hour: 0, amount: 0 }) };
      }

      try {
        const formattedDate = format(date, "dd-MM-yyyy");

        const summaryDocRef = doc(
          db,
          "stores",
          selectedSite,
          "summary",
          formattedDate
        );
        const summaryDocSnap = await getDoc(summaryDocRef);
        const totalSales = summaryDocSnap.exists
          ? summaryDocSnap.data()?.totalSales ?? 0
          : 0;

        const hourlySalesDocRef = doc(
          db,
          "stores",
          selectedSite,
          "summary",
          formattedDate,
          "Hours",
          "hourlySales"
        );
        const hourlySalesDocSnap = await getDoc(hourlySalesDocRef);
        const hourlySalesData = hourlySalesDocSnap.exists
          ? hourlySalesDocSnap.data() ?? 0
          : {};

        const processedHourlySalesData = Object.entries(hourlySalesData).map(
          ([hour, amount]) => ({
            hour: parseInt(hour, 10),
            amount,
          })
        );

        return {
          amount: totalSales,
          data:
            processedHourlySalesData.length > 0
              ? processedHourlySalesData
              : Array(8).fill({ hour: 0, amount: 0 }),
        };
      } catch (error) {
        console.error("Error fetching sales data:", error);
        return { amount: 0, data: Array(8).fill({ hour: 0, amount: 0 }) };
      }
    };

    const fetchWeeklySalesData = async () => {
      if (!selectedSite) {
        setLoading(false);
        return;
      }

      try {
        const startDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
        const endDate = endOfWeek(selectedDate, { weekStartsOn: 1 });

        let totalWeeklySales = 0;
        let weeklyData = [];

        for (let day = startDate; day <= endDate; day = addDays(day, 1)) {
          const formattedDate = format(day, "dd-MM-yyyy");

          const summaryDocRef = doc(
            db,
            "stores",
            selectedSite,
            "summary",
            formattedDate
          );
          const summaryDocSnap = await getDoc(summaryDocRef);
          const dailyTotalSales = summaryDocSnap.exists
            ? summaryDocSnap.data()?.totalSales ?? 0
            : 0;

          totalWeeklySales += dailyTotalSales;
          weeklyData.push({ date: formattedDate, amount: dailyTotalSales });
        }

        setWeeklySales({
          total: totalWeeklySales,
          data: weeklyData,
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching weekly sales data:", error);
        setLoading(false);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      const previousWeekDate = subDays(selectedDate, 7);

      const [currentSalesData, previousSalesData] = await Promise.all([
        fetchSalesData(selectedDate),
        fetchSalesData(previousWeekDate),
      ]);

      setDailySales(currentSalesData);
      setPreviousWeekSales(previousSalesData);

      fetchWeeklySalesData();
    };

    if (selectedDate instanceof Date && !isNaN(selectedDate)) {
      fetchData();
    }
  }, [selectedSite, selectedDate]);

  return (
    <SalesContext.Provider
      value={{ dailySales, previousWeekSales, weeklySales, loading }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export const useSales = () => useContext(SalesContext);
