// src/components/WeeklyATV/WeeklyATV.js
import React, { useContext, useEffect, useState } from "react";
import "../ATV/ATV.css";
import { SiteContext } from "../../context/SiteContext";
import { db, doc, getDoc } from "../../firebase";
import { useSales } from "../../context/SalesContext";
import { format, startOfWeek, endOfWeek, addDays } from "date-fns";

const WeeklyATVValue = () => {
  const { weeklySales, loading: salesLoading } = useSales();
  const { selectedSite, selectedDate } = useContext(SiteContext);
  const [loading, setLoading] = useState(true);
  const [ATVValue, setATVValue] = useState(null);

  useEffect(() => {
    const fetchATVData = async () => {
      if (!selectedSite || !selectedDate) {
        setATVValue(null);
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        const startDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
        const endDate = endOfWeek(selectedDate, { weekStartsOn: 1 });

        let totalSalesCount = 0;

        for (let day = startDate; day <= endDate; day = addDays(day, 1)) {
          const formattedDate = format(day, "dd-MM-yyyy");

          const salesCountRef = doc(
            db,
            "stores",
            selectedSite,
            "summary",
            formattedDate
          );
          const salesCountSnap = await getDoc(salesCountRef);

          if (salesCountSnap.exists()) {
            const salesData = salesCountSnap.data();
            totalSalesCount += salesData.count || 0;
          }
        }

        const ATV =
          totalSalesCount !== 0
            ? (weeklySales.total / totalSalesCount).toFixed(2)
            : 0;
        setATVValue(ATV);
      } catch (error) {
        console.error("Error fetching ATV data: ", error);
        setATVValue(null);
      } finally {
        setLoading(false);
      }
    };

    fetchATVData();
  }, [selectedSite, selectedDate, weeklySales.total]);

  if (loading || salesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="atv-container">
      <div className="label">Weekly ATV</div>
      <div className="amount">${ATVValue || "0.00"}</div>
    </div>
  );
};

export default WeeklyATVValue;
