// src/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';
import './styles/fonts.css';


const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Alexandria';
    src: url('/fonts/Alexandria-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Alexandria';
    src: url('/fonts/Alexandria-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Alexandria';
    src: url('/fonts/Alexandria-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  body {
    font-family: 'Alexandria', sans-serif;
  }
`;

export default GlobalStyles;
