import React from 'react';
import './LocationSettings.css';

const australianTimezones = [
  "Australia/Sydney",
  "Australia/Melbourne",
  "Australia/Brisbane",
  "Australia/Adelaide",
  "Australia/Perth",
  "Australia/Darwin",
  "Australia/Hobart"
];

const hoursOfDay = Array.from({ length: 24 }, (_, i) => {
  const hour = i < 10 ? `0${i}:00` : `${i}:00`;
  return hour;
});

const LocationSettings = ({ settings, setSettings }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  return (
    <div className="location-settings">
      <div className="settings-label">Location Settings</div>
      <div className="loc-set-content">
        <div className="loc-set-list">
          <label>Name:</label>
          <label>Timezone:</label>
          <label>Start of Day:</label>
        </div>
        <div className="loc-set-inputs">
          <input
            type="text"
            name="name"
            value={settings.name}
            onChange={handleChange}
          />
          <select
            name="timezone"
            value={settings.timezone}
            onChange={handleChange}
          >
            {australianTimezones.map(tz => (
              <option key={tz} value={tz}>{tz}</option>
            ))}
          </select>
          <select
            name="startOfDay"
            value={settings.startOfDay}
            onChange={handleChange}
          >
            {hoursOfDay.map(hour => (
              <option key={hour} value={hour}>{hour}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default LocationSettings;
