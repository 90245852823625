import React, { useContext, useState, useEffect } from "react";
import { SiteContext } from "../../../context/SiteContext";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import "../../integration-widgets/integration-tile.css";

const XeroAuth = () => {
  const { selectedSite } = useContext(SiteContext);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const checkConnectionStatus = async () => {
      if (selectedSite) {
        const docRef = doc(
          db,
          "stores",
          selectedSite,
          "IntegrationInfo",
          "XeroIntegration"
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsConnected(docSnap.data().XeroConnected);
        }
      }
    };

    checkConnectionStatus();
  }, [selectedSite]);
  const title = "Xero";
  const description =
    "Break free from accounting woes with better data, decisions and less work.";

  const handleAuth = () => {
    const clientId = "97D84F644CF64B448953EC22D10BC646";
    const scopes =
      "accounting.transactions payroll.employees.read payroll.payruns.read accounting.settings offline_access";
    const redirectUri =
      "https://australia-southeast1-hospo-sense.cloudfunctions.net/xeroOauth/callback";
    const authUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${scopes}&state=${selectedSite}&code=iZqM4C8wUtg74zNA`;

    window.location.href = authUrl;
  };

  return (
    <div className="integration-tile">
      <div className="int-header">
        <img className="logo" src="/icons/xeroLogo.png" alt="Logo" />
        <div className="title">{title}</div>
      </div>
      <div className="description">{description}</div>
      <div className="status">
        <div className="status-indicator">
          <div className={`ellipse ${isConnected ? "connected" : ""}`} />
          <div className="status-text">
            {isConnected ? "Connected" : "Not Connected"}
          </div>
        </div>
        {isConnected ? (
          <button className="connect-button" onClick={handleAuth}>
            Reconnect
          </button>
        ) : (
          <button className="connect-button" onClick={handleAuth}>
            Connect Now
          </button>
        )}
      </div>
    </div>
  );
};

export default XeroAuth;
