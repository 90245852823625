// DataContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getAggregateFromServer,
  sum,
} from "firebase/firestore";
import { format } from "date-fns";
import { db } from "../firebase";
import { SiteContext } from "./SiteContext";

const LabourDataContext = createContext();

export const DataProvider = ({ children }) => {
  const { selectedDate, selectedSite } = useContext(SiteContext);
  const [taxFilter] = useState(0);
  const [data, setData] = useState({
    salesTotal: null,
    labourCostTotal: null,
    totalLabourHours: null,
    departments: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedSite || !selectedDate) {
        setData({
          salesTotal: 0,
          labourCostTotal: 0,
          totalLabourHours: 0,
          departments: null,
        });
        return;
      }

      try {
        const departmentsDocRef = doc(
          db,
          "stores",
          selectedSite,
          "IntegrationInfo",
          "PayrollIntegration",
          "Departments",
          "departments"
        );
        const departmentsDocSnap = await getDoc(departmentsDocRef);

        if (departmentsDocSnap.exists()) {
          const departmentsData = departmentsDocSnap.data();
          const departments = departmentsData.departments;
          const date = format(new Date(selectedDate), "yyyy-MM-dd");

          const coll = collection(db, "shifts");
          const q = query(
            coll,
            where("date", "==", date),
            where("department_id", "in", departments)
          );

          const costAggQuery = await getAggregateFromServer(q, {
            totalLabourCost: sum("cost"),
          });

          const hoursAggQuery = await getAggregateFromServer(q, {
            totalLabourHours: sum("shiftLengthInHours"),
          });

          const labourCostTotal = costAggQuery.data().totalLabourCost || 0;
          const totalLabourHours = hoursAggQuery.data().totalLabourHours || 0;

          const dateFormatted = format(selectedDate, "dd-MM-yyyy");

          const salesDocRef = doc(
            db,
            "stores",
            selectedSite,
            "summary",
            dateFormatted
          );

          const salesDocSnap = await getDoc(salesDocRef);

          let salesTotal = 0;
          if (salesDocSnap.exists()) {
            salesTotal = taxFilter
              ? salesDocSnap.data()?.totalSales
              : salesDocSnap.data()?.totalSalesExTax;
          }

          setData({
            salesTotal: salesTotal || 0,
            labourCostTotal,
            totalLabourHours,
            departments: departmentsData,
          });
        } else {
          setData({
            salesTotal: 0,
            labourCostTotal: 0,
            totalLabourHours: 0,
            departments: null,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedSite, selectedDate, taxFilter]);

  return (
    <LabourDataContext.Provider value={data}>
      {children}
    </LabourDataContext.Provider>
  );
};

export const useData = () => useContext(LabourDataContext);
