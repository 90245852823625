  import React, { useState, useEffect, useContext } from "react";
  import { db, doc, getDoc, collection, query, where, getAggregateFromServer, sum } from "../../firebase";
  import { SiteContext } from "../../context/SiteContext";
  import { format } from "date-fns";
  import "./StoreSummaryTile.css";

  const StoreTile = ({ store, onSiteSelect }) => {
    const { selectedDate } = useContext(SiteContext);
    const [totalSales, setTotalSales] = useState(0);
    const [name, setName] = useState(0);
    const [taxFilter, setTaxFilter] = useState(0);
    const [totalLabourCost, setTotalLabourCost] = useState(0);
    const [totalSPLH, setTotalSPLH] = useState(0);
    const [totalHours, setTotalHours] = useState(0);
    const [wagePercentage, setWagePercentage] = useState(0);
    const [atv, setAtv] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => { 
      const fetchStoreName = async () => {
          const storeNameDoc = doc(db, "stores", store);
          const storeName = await getDoc(storeNameDoc);
          const name = storeName.data()?.name;
          const taxFilter = storeName.data()?.includeTax;
          
          setName(name);
          setTaxFilter(taxFilter);

  };

      fetchStoreName();
  })

    useEffect(() => {
      const fetchTotalSales = async () => {
        if (!store || !selectedDate) {
          setTotalSales(0);
          return;
        }
        if (taxFilter === false) {

          const date = format(selectedDate, "dd-MM-yyyy");
          const summaryDocRef = doc(db, "stores", store, "summary", date);
          const summaryDocSnap = await getDoc(summaryDocRef);
    
          if (!summaryDocSnap.exists()) {
            setTotalSales(0);
            return;
          }
    
          const totalSales = summaryDocSnap.data()?.totalSalesExTax ?? 0;
          setTotalSales(totalSales);
    
          const salesCount = summaryDocSnap.data()?.count ?? 0;
          const atvValue = salesCount !== 0 ? (totalSales / salesCount).toFixed(2) : 0;
          setAtv(atvValue);
    

        } else {
        const date = format(selectedDate, "dd-MM-yyyy");
        const summaryDocRef = doc(db, "stores", store, "summary", date);
        const summaryDocSnap = await getDoc(summaryDocRef);

        if (!summaryDocSnap.exists()) {
          setTotalSales(0);
          return;
        }

        const totalSales = summaryDocSnap.data()?.totalSales ?? 0;
        setTotalSales(totalSales);

        const salesCount = summaryDocSnap.data()?.count ?? 0;
        const atvValue = salesCount !== 0 ? (totalSales / salesCount).toFixed(2) : 0;
        setAtv(atvValue);
      }
      };

      fetchTotalSales();
    }, [store, selectedDate, taxFilter]);

    useEffect(() => {
      const fetchWagesData = async () => {
        if (!store || !selectedDate) {
          setTotalLabourCost(0);
          setLoading(false);
          return;
        }

        setLoading(true);
        try {
          const departmentsDocRef = doc(
              db,
              "stores",
              store,
              "IntegrationInfo",
              "PayrollIntegration",
              "Departments",
              "departments"
              );
          const departmentsDocSnap = await getDoc(departmentsDocRef);

          if (!departmentsDocSnap.exists()) {
            setTotalLabourCost(0);
            setLoading(false);
            return;
          }

          const departments = departmentsDocSnap.data().departments;
          const date = format(selectedDate, "yyyy-MM-dd");

          const coll = collection(db, "shifts");
          const q = query(
            coll,
            where("date", "==", date),
            where("department_id", "in", departments)
          );

          const sumAggregateQuery = getAggregateFromServer(q, {
            totalLabourCost: sum("cost"),
          });
          
          const SPLHQuery = getAggregateFromServer(q, {
            totalLabourHours: sum("shiftLengthInHours"),
          });

          const snapshot = await sumAggregateQuery;
          const data = snapshot.data();
          const totalCost = data.totalLabourCost ?? 0;
          setTotalLabourCost(totalCost);

          const SPLHsnapshot = await SPLHQuery;
          const SPLHdata = SPLHsnapshot.data();
          const totalHours = SPLHdata.totalLabourHours ?? 0;
          setTotalHours(totalHours);

          setLoading(false);
        } catch (error) {
          console.error("Error fetching wages data:", error);
          setTotalLabourCost(0);
          setLoading(false);
        }
      };

      fetchWagesData();
    }, [store, selectedDate]);

    useEffect(() => {
      if (totalHours !== 0 && totalSales !== 0) {
        const SPLH = (totalSales / totalHours);
        setTotalSPLH(SPLH.toFixed(2));
      } else {
        setTotalSPLH(0);
      }
    }, [totalHours, totalSales]);

    useEffect(() => {
      if (totalLabourCost !== 0 && totalSales !== 0) {
        const wagePercentage = (totalLabourCost / totalSales) * 100;
        setWagePercentage(wagePercentage.toFixed(2));
      } else {
        setWagePercentage(0);
      }
    }, [totalLabourCost, totalSales]);

    if (loading) {
      return;
    }

    return (
  <div className="tile-container">
    <div className="tile-header">
      <span>{name}</span>
      <button onClick={() => onSiteSelect(store)} className="tile-button">
        <img src="/images/dashboardsq.svg" alt="icon" />
      </button>
    </div>
    <div className="tile-content">
      <div className="tile-grid">
        <div className="tile-cell">
          <img src="/images/graph.png" alt="icon" className="tile-icon" />
          <div className="tile-text">
            <span>Total Revenue</span>
            <span className="tile-value">{`$${totalSales.toFixed(2)}`}</span>
          </div>
        </div>
        <div className="tile-cell">
          <div className="tile-label">
              <span>ATV</span>
          <span className="tile-value">{`$${atv}`}</span>
          </div>
        </div>
        <div className="tile-cell">
          <img src="/images/dollaricon.png" alt="icon" className="tile-icon" />
          <div className="tile-text">
            <span>Labour Percentage</span>
            <span className="tile-value">{`${wagePercentage}%`}</span>
          </div>
        </div>
        <div className="tile-cell">
          <div className="tile-label">
              <span>SPLH</span>
          <span className="tile-value">{`$${totalSPLH}`}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
    );
  };

  export default StoreTile;