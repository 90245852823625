import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../../../firebase";
import { getDoc, doc, setDoc, updateDoc, query, where, collection, getDocs } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import "./AddProfile.css";

const AddProfileModal = ({ onClose }) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [stores, setStores] = useState([]); // Array of objects with id, name, and company
  const [selectedStores, setSelectedStores] = useState([]); // Array of selected store IDs
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userProfileRef = doc(db, "profiles", user.uid);
          const userProfileSnap = await getDoc(userProfileRef);
          if (userProfileSnap.exists()) {
            const userStores = userProfileSnap.data().stores || [];
            const storeNames = await Promise.all(
              userStores.map(async (storeId) => {
                const storeDoc = await getDoc(doc(db, "stores", storeId));
                return { id: storeId, name: storeDoc.data().name, company: storeDoc.data().company };
              })
            );
            setStores(storeNames);
          }
        }
      } catch (error) {
        console.error("Error fetching stores: ", error);
      }
    };

    fetchStores();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      const newUser = await createUserWithEmailAndPassword(auth, email, "defaultPassword"); // You may want to handle password differently
      const userUid = newUser.user.uid;

      const selectedStoresWithCompany = selectedStores.map(storeId => {
        const store = stores.find(s => s.id === storeId);
        return { id: storeId, company: store.company };
      });

      const companies = [...new Set(selectedStoresWithCompany.map(store => store.company))];

      await setDoc(doc(db, "profiles", userUid), {
        name: userName,
        email: email,
        stores: selectedStores,
        companies: companies
      });

      onClose(); // Close the modal
      navigate("/settings"); // Navigate to user management page or any other page

    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        // User exists, fetch the UID using Firestore queries
        const userQuery = query(collection(db, "profiles"), where("email", "==", email));
        const querySnapshot = await getDocs(userQuery);
        let userUid;
        querySnapshot.forEach((doc) => {
          userUid = doc.id;
        });

        const userDocRef = doc(db, "profiles", userUid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const existingData = userDoc.data();
          const existingStores = existingData.stores || [];
          const existingCompanies = existingData.companies || [];

          const selectedStoresWithCompany = selectedStores.map(storeId => {
            const store = stores.find(s => s.id === storeId);
            return { id: storeId, company: store.company };
          });

          const newStores = [...new Set([...existingStores, ...selectedStores])];
          const newCompanies = [...new Set([...existingCompanies, ...selectedStoresWithCompany.map(store => store.company)])];

          await updateDoc(userDocRef, {
            stores: newStores,
            companies: newCompanies
          });

          onClose(); // Close the modal
          navigate("/settings"); // Navigate to user management page or any other page
        } else {
          console.error("User document does not exist, but sign-in methods indicate the email is in use.");
        }
      } else {
        console.error("Error creating or updating user: ", error);
      }
    }
  };
  
  
  const handleStoreChange = (storeId) => {
    setSelectedStores((prevSelectedStores) =>
      prevSelectedStores.includes(storeId)
        ? prevSelectedStores.filter((id) => id !== storeId)
        : [...prevSelectedStores, storeId]
    );
  };

  return (
    <div className="ap-modal-backdrop" onClick={onClose}>
      <div className="ap-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="ap-title">Add a new user **Experimental**</div>
        <form onSubmit={handleSubmit}>
          <div className="ap-form-horizontal">
            <div className="ap-form-group">
              <label>User Name</label>
              <input
                type="text"
                placeholder="ex: John Doe"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </div>
            <div className="ap-form-group">
              <label>Email</label>
              <input
                type="email"
                placeholder="ex: johndoe@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="ap-store-container">
            <label>Assign Stores</label>
            <div className="ap-store-grid">
              {stores.map((store) => (
                <div key={store.id}>
                  <input
                    type="checkbox"
                    id={store.id}
                    value={store.id}
                    onChange={() => handleStoreChange(store.id)}
                  />
                  <label htmlFor={store.id}>{store.name}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="ap-button-group">
            <button type="button" className="ap-modal-close" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="ap-submit-button">
              ADD USER
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProfileModal;
