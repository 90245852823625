import React, { useState, useEffect, useContext } from "react";
import { db } from "../../../firebase";
import { doc, getDoc, getDocs, collection, setDoc } from "firebase/firestore";
import { SiteContext } from "../../../context/SiteContext";
import "./mapping-modal.css";

const MappingModal = ({ onClose }) => {
  const { selectedCompany } = useContext(SiteContext);
  const [locations, setLocations] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeMappings, setStoreMappings] = useState({});
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const fetchLocationsAndStores = async () => {
      if (selectedCompany) {
        // Fetch Tanda Locations
        const locationsSnapshot = await getDocs(
          collection(
            db,
            "companies",
            selectedCompany,
            "IntegrationInfo",
            "TandaInfo",
            "TandaLocations"
          )
        );
        const locationsData = locationsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLocations(locationsData);

        // Fetch Stores and their current mappings
        const storesSnapshot = await getDocs(
          collection(db, "companies", selectedCompany, "Stores")
        );
        const storesData = storesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Set initial store mappings based on current `locationIds`
        const initialMappings = {};
        storesData.forEach((store) => {
          initialMappings[store.id] = store.locationIds || [];
        });
        setStoreMappings(initialMappings);

        setStores(storesData);

        // Check if Tanda is connected
        const docRef = doc(
          db,
          "companies",
          selectedCompany,
          "IntegrationInfo",
          "TandaTokens"
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsConnected(docSnap.data().TandaConnected);
        }
      }
    };

    fetchLocationsAndStores();
  }, [selectedCompany]);

  const handleCheckboxChange = (storeId, locationId) => {
    // Update store mappings when checkboxes are toggled
    setStoreMappings((prevState) => {
      const storeMapping = prevState[storeId] || [];
      if (storeMapping.includes(locationId)) {
        return {
          ...prevState,
          [storeId]: storeMapping.filter((id) => id !== locationId),
        };
      } else {
        return {
          ...prevState,
          [storeId]: [...storeMapping, locationId],
        };
      }
    });
  };

  const handleSave = async () => {
    // Save the mappings to Firestore
    for (const [storeId, locationIds] of Object.entries(storeMappings)) {
      const departmentIds = [];

      // Fetch department arrays for selected locations and combine them
      for (const locationId of locationIds) {
        const locationPath = `companies/${selectedCompany}/IntegrationInfo/TandaInfo/TandaLocations/${locationId}`;
        const locationDocRef = doc(db, locationPath);
        const locationDocSnap = await getDoc(locationDocRef);
        if (locationDocSnap.exists()) {
          const { departments } = locationDocSnap.data();
          if (Array.isArray(departments)) {
            departmentIds.push(...departments);
          } else {
            console.error(
              `Departments field for location ${locationId} is not an array:`,
              departments
            );
          }
        } else {
          console.error(`No document found for location ${locationId}`);
        }
      }

      // Remove duplicates from the combined department IDs array
      const uniqueDepartmentIds = [...new Set(departmentIds)];

      const integrationPath = `/stores/${storeId}/IntegrationInfo/PayrollIntegration/Departments/departments`;
      const integrationDocRef = doc(db, integrationPath);
      await setDoc(integrationDocRef, {
        departments: uniqueDepartmentIds,
      });

      console.log(
        "Store ID: ",
        storeId,
        " || ",
        "Departments: ",
        uniqueDepartmentIds
      );

      fetch(
        "https://australia-southeast1-hospo-sense.cloudfunctions.net/labour-summary-backdate",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ storeId, departments: uniqueDepartmentIds }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            console.log(
              `Labor summary and breaks processed for store ${storeId}`
            );
          } else {
            console.error(
              `Failed to process labor summary and breaks for store ${storeId}`,
              result
            );
          }
        })
        .catch((error) => {
          console.error(
            `Error processing labor summary and breaks for store ${storeId}`,
            error
          );
        });
    }
    onClose();
  };

  return (
    <div className="mm-modal-overlay">
      <div className="mm-modal-content">
        <div className="mm-header">
          <div className="mm-title">Tanda Connection Settings</div>
          <div className="mm-subtitle">
            Select which Tanda Locations are connected to your HospoSense Stores
          </div>
        </div>
        {isConnected ? (
          <div className="mapping-form">
            {stores.map((store) => (
              <div key={store.id} className="store-box">
                <div className="store-title">{store.name}</div>
                <div className="locations">
                  {locations.map((location) => (
                    <div key={location.id} className="location-item">
                      <span>{location.name}</span>
                      <label className="toggle-container">
                        <input
                          type="checkbox"
                          id={`${store.id}-${location.id}`}
                          checked={(storeMappings[store.id] || []).includes(
                            location.id
                          )}
                          onChange={() =>
                            handleCheckboxChange(store.id, location.id)
                          }
                        />
                        <div className="mm-toggle">
                          <div className="mm-rectangle"></div>
                          <div className="mm-ellipse"></div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="tm-button-group">
              <button className="tm-button delete-button">
                Delete Connection
              </button>
              <button className="tm-button reconnect-button">Reconnect</button>
              <button className="tm-button save-button" onClick={handleSave}>
                Save Settings
              </button>
            </div>
          </div>
        ) : (
          <div className="connect-message">Please connect to Tanda first.</div>
        )}
      </div>
    </div>
  );
};

export default MappingModal;
