import React, { useState, useEffect, useContext } from "react";
import { db } from "../../../firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
} from "firebase/firestore";
import { SiteContext } from "../../../context/SiteContext";
import { format, sub } from "date-fns";
import "./mapping-modal.css";

const MappingModal = ({ onClose }) => {
  const { selectedCompany } = useContext(SiteContext);
  const [sites, setSites] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeMappings, setStoreMappings] = useState({});
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const fetchSitesAndStores = async () => {
      if (selectedCompany) {
        // Fetch Kounta Sites
        const sitesSnapshot = await getDocs(
          collection(
            db,
            "companies",
            selectedCompany,
            "IntegrationInfo",
            "KountaInfo",
            "KountaSites"
          )
        );
        const sitesData = sitesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSites(sitesData);

        // Fetch Stores and their current mappings
        const storesSnapshot = await getDocs(
          collection(db, "companies", selectedCompany, "Stores")
        );
        const storesData = storesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Set initial store mappings based on current `SiteIds`
        const initialMappings = {};
        storesData.forEach((store) => {
          initialMappings[store.id] = store.siteIds || [];
        });
        setStoreMappings(initialMappings);

        setStores(storesData);

        // Check if Kounta is connected
        const docRef = doc(
          db,
          "companies",
          selectedCompany,
          "IntegrationInfo",
          "KountaInfo"
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsConnected(docSnap.data().KountaConnected);
        }
      }
    };

    fetchSitesAndStores();
  }, [selectedCompany]);

  const handleCheckboxChange = (storeId, siteId) => {
    // Update store mappings when checkboxes are toggled
    setStoreMappings((prevState) => {
      const storeMapping = prevState[storeId] || [];
      if (storeMapping.includes(siteId)) {
        return {
          ...prevState,
          [storeId]: storeMapping.filter((id) => id !== siteId),
        };
      } else {
        return {
          ...prevState,
          [storeId]: [...storeMapping, siteId],
        };
      }
    });
  };

  const handleSave = async () => {
    // Save the mappings to Firestore
    console.log("HandleSave Started");
    for (const [storeId, siteIds] of Object.entries(storeMappings)) {
      const siteString = siteIds.toString();
      const storePath = `companies/${selectedCompany}/Stores/${storeId}`;
      const storeDocRef = doc(db, storePath);
      const storeDocSnap = await getDoc(storeDocRef);

      console.log(siteIds);

      if (storeDocSnap.exists()) {
        const currentSiteId = storeDocSnap.data().siteId;
        console.log("currentSiteId: :,", currentSiteId);

        if (currentSiteId !== siteString) {
          await updateDoc(storeDocRef, {
            siteId: siteString,
          });

          const locationDataPath = `stores/${storeId}`;
          const locationRef = doc(db, locationDataPath);
          await updateDoc(locationRef, {
            POSID: siteString,
          });

          if (siteIds.length > 0) {
            // Fetch KountaCompanyID
            const kountaInfoRef = doc(
              db,
              "companies",
              selectedCompany,
              "IntegrationInfo",
              "KountaInfo"
            );
            const kountaInfoSnap = await getDoc(kountaInfoRef);
            const kountaCompanyID = kountaInfoSnap
              .data()
              .KountaCompanyID.toString();
            console.log("kountaCompanyID: ", kountaCompanyID);

            // Fetch access_token
            const apiKeyRef = doc(db, "lightspeed-api-keys", kountaCompanyID);
            const apiKeySnap = await getDoc(apiKeyRef);
            const accessToken = apiKeySnap.data().access_token;
            const authInfo = `Bearer ${accessToken}`;

            const webhookDetails = {
              topic: "orders/completed",
              address: `https://australia-southeast1-hospo-sense.cloudfunctions.net/lsSales-q?storename=${storeId}`,
              format: "json",
              filter: {
                site_ids: siteIds,
              },
            };

            fetch(
              `https://api.kounta.com/v1/companies/${kountaCompanyID}/webhooks.json`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: authInfo,
                },
                body: JSON.stringify(webhookDetails),
              }
            );

            // Prepare details for POST request
            const postDetails = {
              startDate: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
              endDate: format(new Date(), "yyyy-MM-dd"),
              selectedSite: storeId,
              siteID: currentSiteId,
            };

            // Send POST request
            fetch(
              "https://australia-southeast1-hospo-sense.cloudfunctions.net/ls-backdate-summary",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(postDetails),
              }
            );
            console.log("fetch call done");
          }
        }
      }
    }
    onClose();
  };

  return (
    <div className="mm-modal-overlay">
      <div className="mm-modal-content">
        <div className="mm-header">
          <div className="mm-title">Lightspeed Connection Settings</div>
          <div className="mm-subtitle">
            Select which Lightspeed Sites are connected to your HospoSense
            Stores
          </div>
        </div>
        {isConnected ? (
          <div className="mapping-form">
            {stores.map((store) => (
              <div key={store.id} className="store-box">
                <div className="store-title">{store.name}</div>
                <div className="locations">
                  {sites.map((site) => (
                    <div key={site.id} className="location-item">
                      <span>{site.name}</span>
                      <label className="toggle-container">
                        <input
                          type="checkbox"
                          id={`${store.id}-${site.id}`}
                          checked={(storeMappings[store.id] || []).includes(
                            site.id
                          )}
                          onChange={() =>
                            handleCheckboxChange(store.id, site.id)
                          }
                        />
                        <div className="mm-toggle">
                          <div className="mm-rectangle"></div>
                          <div className="mm-ellipse"></div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="modal-buttons">
              <button className="mm-button delete-button">
                Delete Connection
              </button>
              <button className="mm-button reconnect-button">Reconnect</button>
              <button className="mm-button save-button" onClick={handleSave}>
                Save Settings
              </button>
            </div>
          </div>
        ) : (
          <div className="connect-message">
            Please connect to Lightspeed first.
          </div>
        )}
      </div>
    </div>
  );
};

export default MappingModal;
