import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import StoreTile from "./StoreSummaryTile/StoreSummaryTile";
import { SiteContext } from "../context/SiteContext";
import { Sling as Hamburger } from "hamburger-react";
import Navbar from "../global-components/navbar/navbar";
import DateSelector from "../main-dash-components/DatePicker/DateSelector";
import "./multiStoreDashboard.css";

const MultiSiteDashboard = () => {
  const [stores, setStores] = useState([]);
  const navigate = useNavigate();
  const { setSelectedSite } = useContext(SiteContext);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setMenuOpen((prevState) => !prevState);
  }, []);

  const fetchStores = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "profiles", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userStores = userDoc.data().stores || [];
          setStores(userStores);
          if (userStores.length === 1) {
            setSelectedSite(userStores[0].id);
            navigate("/dashboard");
          }
        } else {
          console.log("User document does not exist.");
        }
      } else {
        console.log("No authenticated user.");
      }
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  }, [navigate, setSelectedSite]);

  useEffect(() => {
    fetchStores();
  }, [fetchStores]);

  const handleSiteSelect = useCallback(
    (store) => {
      setSelectedSite(store); // Ensure this is setting the correct store ID
      localStorage.setItem("selectedSite", store);
      navigate("/dashboard");
    },
    [navigate, setSelectedSite]
  );

  return (
    <div className="ms-main-container">
      <Navbar isMenuOpen={isMenuOpen} onMenuToggle={toggleMenu} />
      <div className="ms-dashboard">
        <div className="ms-header-row">
          <div className="ms-hamburger">
            <Hamburger toggled={isMenuOpen} toggle={toggleMenu} />
          </div>
          <div className="ms-date">
            <DateSelector />
          </div>
        </div>
        <div className="dashboard-container">
          {stores.map((store, index) => (
            <StoreTile key={index} store={store} onSiteSelect={() => handleSiteSelect(store)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiSiteDashboard;
