import React, { useState, useEffect, useContext, useMemo } from "react";
import { db, collection, query, where, getAggregateFromServer, sum } from "../../../firebase";
import { SiteContext } from "../../../context/SiteContext";
import { format, eachDayOfInterval } from "date-fns";
import { Bar } from "react-chartjs-2";
import { useData } from "../../../context/LabourDataContext";
import "./PremiumGraph.css";

const LabourRange = () => {
  const { selectedSite, selectedDateRange } = useContext(SiteContext);
  const [rangedLabour, setRangedLabour] = useState({
    totalAmount: 0,
    data: [],
  });
  const [loading, setLoading] = useState(true);
  const { departments } = useData();

  useEffect(() => {
    const fetchRangedLabourData = async () => {
      if (!selectedSite || !selectedDateRange || !departments) {
        setLoading(false);
        return;
      }

      try {
        const startDate = selectedDateRange[0];
        const endDate = selectedDateRange[1];

        const dates = eachDayOfInterval({
          start: startDate,
          end: endDate,
        });

        const labourData = [];
        const storeDepartments = departments.departments || [];

        for (let date of dates) {
          const theDate = format(new Date(date), "yyyy-MM-dd");
          const reportDate = format(date, "dd-MMM");
          const coll = collection(db, "shifts");
          const q = query(
            coll,
            where("date", "==", theDate),
            where("department_id", "in", storeDepartments)
          );

          const costAggQuery = await getAggregateFromServer(q, {
            totalLabourCost: sum("cost"),
          });

          const labour = costAggQuery.data()?.totalLabourCost || 0;
          labourData.push({ date: reportDate, labour });
        }

        const totalAmount = labourData.reduce((acc, curr) => acc + curr.labour, 0);

        setRangedLabour({
          totalAmount,
          data: labourData,
        });

      } catch (error) {
        console.error("Error fetching labour data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRangedLabourData();
  }, [selectedSite, selectedDateRange, departments]);

  const chartData = useMemo(() => ({
    labels: rangedLabour.data.map((item) => item.date),
    datasets: [
      {
        label: "Labour",
        data: rangedLabour.data.map((item) => item.labour),
        backgroundColor: "#00a6fb",
        borderRadius: 10,
      },
    ],
  }), [rangedLabour]);

  const options = useMemo(() => ({
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          maxTicksLimit: 5,
        },
      },
      x: {
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "rgba(255, 255, 255, 0.7)",
        },
        maintainAspectRatio: false,
        responsive: true,
      },
    },
  }), []);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="ab-PremiumGraph">
          <Bar data={chartData} options={options} width={500} />
        </div>
      )}
    </div>
  );
};

export default LabourRange;
