// src/integrations-page/IntegrationTileWithButton.js
import React, { useContext, useState, useEffect } from "react";
import { db } from "../../../firebase";
import { SiteContext } from "../../../context/SiteContext";
import { doc, getDoc } from "firebase/firestore";
import "../../integration-widgets/integration-tile.css";
import MappingModal from "./mapping-modal";

const DeputyAuth = () => {
  const { selectedCompany } = useContext(SiteContext);
  const [isConnected, setIsConnected] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const checkConnectionStatus = async () => {
      if (selectedCompany) {
        const docRef = doc(
          db,
          "companies",
          selectedCompany,
          "IntegrationInfo",
          "DeputyInfo"
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsConnected(docSnap.data().DeputyConnected);
        }
      }
    };

    checkConnectionStatus();
  }, [selectedCompany]);

  const title = "Deputy";
  const description =
    "Create rosters, tasks and more! Schedule staff efficiently and connect your teams.";

  const handleAuth = () => {
    const clientId = "d177acbc0ae34505307f76b34bc078a5c2440b51";
    const scopes = "longlife_refresh_token";
    const redirectUri =
      "https://australia-southeast1-hospo-sense.cloudfunctions.net/deputyOauth/callback";
    const authUrl = `https://once.deputy.com/my/oauth/login?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes}&state=${selectedCompany}`;

    window.location.href = authUrl;
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="integration-tile">
      <div className="int-header">
        <img className="logo" src="/icons/deputylogo.jpeg" alt="Logo" />
        <div className="title">{title}</div>
      </div>
      <div className="description">{description}</div>
      <div className="status">
        <div className="status-indicator">
          <div className={`ellipse ${isConnected ? "connected" : ""}`} />
          <div className="status-text">
            {isConnected ? "Connected" : "Not Connected"}
          </div>
        </div>
        {isConnected ? (
          <button className="connect-button" onClick={openModal}>
            Settings
          </button>
        ) : (
          <button className="connect-button" onClick={handleAuth}>
            Connect Now
          </button>
        )}
      </div>
      {modalOpen && <MappingModal onClose={closeModal} />}
    </div>
  );
};

export default DeputyAuth;
