import React, { useState, useEffect, useContext } from "react";
import Navbar from "../navbar/navbar";
import SiteSelector from "../../main-dash-components/SiteSelector/SiteSelector";
import LocationSettings from "./LocationSettings/LocationSettings";
import NotificationSettings from "./NotificationSettings/NotificationSettings";
import ReportSettings from "./ReportSettings/ReportSettings";
import AddManagement from "./AddNew/AddManagement/AddManagement";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { SiteContext } from "../../context/SiteContext";
import "./settings.css";

const SettingsPage = () => {
  const { selectedSite } = useContext(SiteContext);

  const [locationSettings, setLocationSettings] = useState({
    name: "",
    timezone: "Australia/Brisbane",
    startOfDay: "00:00",
  });

  const [notificationSettings, setNotificationSettings] = useState({
    labourWarningThreshold: 0.0,
    notificationRecipients: "Admins",
    endOfDay: "00:00",
  });

  const [userManagement, setUserManagement] = useState({});
  const [reportSettings, setReportSettings] = useState({
    includeTax: true,
    superannuation: 0.115,
  });

  const [settingsSaved, setSettingsSaved] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      if (selectedSite) {
        const docRef = doc(db, "stores", selectedSite);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setLocationSettings({
            name: data.name ?? "",
            timezone: data.timezone ?? "Australia/Brisbane",
            startOfDay: data.startOfDay ?? "00:00",
          });
          setNotificationSettings(
            data.notificationSettings ?? {
              labourWarningThreshold: 0.0,
              notificationRecipients: "Admins",
              endOfDay: "00:00",
            }
          );
          setUserManagement(data.userManagement ?? {});
          setReportSettings({
            includeTax: data.includeTax ?? true,
            superannuation: data.superannuation ?? 0.115,
          });
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchSettings();
  }, [selectedSite]);

  const handleSave = async () => {
    if (selectedSite) {
      const docRef = doc(db, "stores", selectedSite);
      await updateDoc(docRef, {
        name: locationSettings.name ?? "",
        timezone: locationSettings.timezone ?? "Australia/Brisbane",
        startOfDay: locationSettings.startOfDay ?? "00:00",
        notificationSettings: notificationSettings ?? {
          labourWarningThreshold: 0.0,
          notificationRecipients: "Admins",
          endOfDay: "00:00",
        },
        userManagement: userManagement ?? {},
        includeTax: reportSettings.includeTax ?? true,
        superannuation: reportSettings.superannuation ?? 0.115,
      });
      setSettingsSaved(true);
      setTimeout(() => {
        setSettingsSaved(false);
      }, 3000); // Hide the banner after 3 seconds
      console.log("Document successfully updated!");
    }
  };

  return (
    <div className="main-container">
      <Navbar />
      <div className="main-settings">
        <div className="header">
          <SiteSelector />
        </div>
        <div className="settings-content">
          <LocationSettings
            settings={locationSettings}
            setSettings={setLocationSettings}
          />
          <AddManagement />
          <NotificationSettings
            settings={notificationSettings}
            setSettings={setNotificationSettings}
          />
          <ReportSettings
            settings={reportSettings}
            setSettings={setReportSettings}
          />
        </div>
        <button className="save-button" onClick={handleSave}>
          Save Settings
        </button>
        {settingsSaved && (
          <div className="settings-saved-banner">Settings Saved</div>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;
