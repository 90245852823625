import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const OAuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");

      if (code) {
        try {
          const response = await axios.get(
            "https://australia-southeast1-hospo-sense.cloudfunctions.net/lightspeedOauth/callback",
            { params: { code } }
          );

          // If the tokens are stored successfully, navigate to the integrations page
          if (response.data.message === "Tokens stored successfully") {
            navigate("/integrations");
          } else {
            console.error("Unexpected response:", response.data);
            // Handle unexpected response appropriately
          }
        } catch (error) {
          console.error("Error during OAuth flow", error);
          // Handle error appropriately
        }
      } else {
        console.error("No authorization code found in URL");
        // Handle error appropriately
      }
    };

    handleAuth();
  }, [navigate]);

  return <div>Completing OAuth process...</div>;
};

export default OAuthCallback;
