import React, { useEffect, useContext } from 'react';
import { db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { SiteContext } from '../../../context/SiteContext';
import './NotificationSettings.css';

const NotificationSettings = ({ settings, setSettings }) => {
  const { selectedSite } = useContext(SiteContext);

  const hoursOfDay = Array.from({ length: 24 }, (_, i) => {
    const hour = i < 10 ? `0${i}:00` : `${i}:00`;
    return hour;
  });

  const handleLabourThresholdChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      labourWarningThreshold: parseFloat(value) / 100 // Convert percentage to number
    }));
  };

  const handleRecipientsChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      notificationRecipients: value
    }));
  };

  const handleEndOfDayChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      endOfDay: value
    }));
  };

  const formatPercentage = (value) => {
    return (value * 100).toFixed(2); // Format number to percentage
  };

  useEffect(() => {
    const fetchNotificationSettings = async () => {
      if (selectedSite) {
        const docRef = doc(db, 'stores', selectedSite, 'Notifications', 'NotificationSettings');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setSettings({
            labourWarningThreshold: data.labourWarningThreshold ?? 0.0,
            notificationRecipients: data.notificationRecipients ?? 'Admins',
            endOfDay: data.endOfDay ?? '00:00'
          });
        } else {
          console.log('No such document!');
        }
      }
    };

    fetchNotificationSettings();
  }, [selectedSite, setSettings]);

  return (
    <div className="notification-settings">
      <div className="settings-label">Notification Settings</div>
      <p className="coming-soon">COMING SOON</p>
      <div className="not-set-content">
        <div className="not-set-list">
          <label>Labour Warning Threshold:</label>
          <label>Notification Recipients:</label>
          <label>End of Day:</label>
        </div>
        <div className="not-set-inputs">
          <div className="input-wrapper">
            <input
              type="number"
              name="labourWarningThreshold"
              value={formatPercentage(settings.labourWarningThreshold)}
              onChange={handleLabourThresholdChange}
              step="0.01"
              min="0"
            />
            <span className="percentage-symbol">%</span>
          </div>
          <select
            name="notificationRecipients"
            value={settings.notificationRecipients}
            onChange={handleRecipientsChange}
          >
            <option value="Admins">Admins</option>
            <option value="All">All</option>
          </select>
          <select
            name="endtOfDay"
            value={settings.endOfDay}
            onChange={handleEndOfDayChange}
          >
            {hoursOfDay.map(hour => (
              <option key={hour} value={hour}>{hour}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
