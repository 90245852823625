// src/components/WeeklyWagePercentage/WeeklyWagePercentage.js
import React, { useContext, useEffect, useState } from "react";
import "./WeeklyWagePercentage.css";
import {
  db,
  doc,
  getDoc,
  collection,
  query,
  where,
  getAggregateFromServer,
  sum,
} from "../../firebase";
import { SiteContext } from "../../context/SiteContext";
import { startOfWeek, endOfWeek, format } from "date-fns";
import { useSales } from "../../context/SalesContext";

const WeeklyWagePercentage = () => {
  const { weeklySales, loading: salesLoading } = useSales();
  const { selectedSite, selectedDate } = useContext(SiteContext);
  const [loading, setLoading] = useState(true);
  const [totalLabourCost, setTotalLabourCost] = useState(0);
  const [wagePercentage, setWagePercentage] = useState(null);

  useEffect(() => {
    const fetchWagesData = async () => {
      if (!selectedSite || !selectedDate) {
        setTotalLabourCost(0);
        setWagePercentage(null);
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const departmentsDocRef = doc(
          db,
          "stores",
          selectedSite,
          "IntegrationInfo",
          "PayrollIntegration",
          "Departments",
          "departments"
        );
        const departmentsDocSnap = await getDoc(departmentsDocRef);

        if (!departmentsDocSnap.exists()) {
          setTotalLabourCost(0);
          setWagePercentage(null);
          setLoading(false);
          return;
        }

        const departments = departmentsDocSnap.data().departments;
        const startDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
        const endDate = endOfWeek(selectedDate, { weekStartsOn: 1 });

        const coll = collection(db, "shifts");
        const q = query(
          coll,
          where("date", ">=", format(startDate, "yyyy-MM-dd")),
          where("date", "<=", format(endDate, "yyyy-MM-dd")),
          where("department_id", "in", departments)
        );

        const sumAggregateQuery = getAggregateFromServer(q, {
          totalLabourCost: sum("cost"),
        });

        const snapshot = await sumAggregateQuery;
        const data = snapshot.data();
        const totalCost = data.totalLabourCost ?? 0;
        setTotalLabourCost(totalCost);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching wages data:", error);
        setTotalLabourCost(0);
        setWagePercentage(null);
        setLoading(false);
      }
    };

    fetchWagesData();
  }, [selectedSite, selectedDate]);

  useEffect(() => {
    if (totalLabourCost !== 0 && weeklySales.total !== 0) {
      const wagePercentage = (totalLabourCost / weeklySales.total) * 100;
      setWagePercentage(wagePercentage.toFixed(2));
    } else {
      setWagePercentage(0);
    }
  }, [totalLabourCost, weeklySales.total]);

  if (loading || salesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="wage-percentage-container">
      <div className="label">Weekly Wage Percentage</div>
      <div className="amount">
        {wagePercentage !== null ? `${wagePercentage}%` : "0.00%"}
      </div>
    </div>
  );
};

export default WeeklyWagePercentage;
