/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";

const PrivateRoute = ({ requiredPermissions }) => {
  const { user, isAdmin, loading } = useAuth();

  if (loading) {
    console.log("Loading user data...");
    return <div>Loading...</div>;
  }

  const hasPermission =
    requiredPermissions.length === 0 ||
    (requiredPermissions.includes("isAdmin") && isAdmin);

  if (!user || !hasPermission) {
    console.log("Redirecting: User does not have permission");
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
