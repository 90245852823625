import React, { useState, useEffect, useContext, useCallback } from "react";
import { db, auth } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import "./SiteSelector.css";
import { SiteContext } from "../../context/SiteContext";

const SiteSelector = () => {
  const { selectedSite, setSelectedSite, setSelectedCompany, setSelectedTax } =
    useContext(SiteContext);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCompany = useCallback(
    async (selectedSite) => {
      if (selectedSite) {
        try {
          const storeDocRef = doc(db, "stores", selectedSite);
          const storeDoc = await getDoc(storeDocRef);
          if (storeDoc.exists()) {
            const company = storeDoc.data().company;
            setSelectedCompany(company);
          }
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      }
    },
    [setSelectedCompany]
  );
  const fetchTaxSettings = useCallback(
    async (selectedSite) => {
      if (selectedSite) {
        try {
          const storeDocRef = doc(db, "stores", selectedSite);
          const storeDoc = await getDoc(storeDocRef);
          if (storeDoc.exists()) {
            const company = storeDoc.data().includeTax;
            setSelectedTax(company);
          }
        } catch (error) {
          console.error("Error fetching tax data:", error);
          setSelectedTax(true);
        }
      }
    },
    [setSelectedTax]
  );

  useEffect(() => {
    const fetchCompaniesAndStores = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, "profiles", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const role = userDoc.data().role;
            const userStores = userDoc.data().stores || [];
            let companiesWithStores = [];
            if (role === "SUPERADMIN") {
              const companiesSnapshot = await getDocs(
                collection(db, "companies")
              );
              for (const companyDoc of companiesSnapshot.docs) {
                const companyId = companyDoc.id;
                const companyData = companyDoc.data();

                const storesQuery = query(
                  collection(db, "stores"),
                  where("company", "==", companyId)
                );
                const storesSnapshot = await getDocs(storesQuery);
                let stores = [];
                storesSnapshot.forEach((storeDoc) => {
                  stores.push({ id: storeDoc.id, ...storeDoc.data() });
                });

                companiesWithStores.push({
                  id: companyId,
                  name: companyData.name,
                  stores: stores,
                });
              }
            } else {
              const userCompanies = userDoc.data().companies || [];
              for (let companyId of userCompanies) {
                const companyDocRef = doc(db, "companies", companyId);
                const companyDoc = await getDoc(companyDocRef);

                if (companyDoc.exists()) {
                  const companyData = companyDoc.data();
                  const storesQuery = query(
                    collection(db, "stores"),
                    where("company", "==", companyId)
                  );
                  const storesSnapshot = await getDocs(storesQuery);

                  let stores = [];
                  storesSnapshot.forEach((storeDoc) => {
                    if (userStores.includes(storeDoc.id)) {
                      stores.push({ id: storeDoc.id, ...storeDoc.data() });
                    }
                  });

                  companiesWithStores.push({
                    id: companyId,
                    name: companyData.name,
                    stores: stores,
                  });
                }
              }
            }

            setCompanies(companiesWithStores);

            if (
              !selectedSite &&
              companiesWithStores.length > 0 &&
              companiesWithStores[0].stores.length > 0
            ) {
              const firstStore = companiesWithStores[0].stores[0];
              setSelectedSite(firstStore.id);
              fetchCompany(firstStore.id);
              fetchTaxSettings(firstStore.id);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching companies and stores:", error);
      } finally {
        setLoading(false);
      }
    };

    if (companies.length === 0) {
      fetchCompaniesAndStores();
    }
  }, [
    companies.length,
    fetchCompany,
    setSelectedSite,
    selectedSite,
    fetchTaxSettings,
  ]);

  useEffect(() => {
    if (selectedSite) {
      localStorage.setItem("selectedSite", JSON.stringify(selectedSite));
    }
  }, [selectedSite]);

  const handleChange = (event) => {
    const selectedSite = event.target.value;
    setSelectedSite(selectedSite);
    fetchCompany(selectedSite);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="location-selector">
      <select
        value={selectedSite || ""}
        onChange={handleChange}
        className="site-dropdown"
      >
        {!selectedSite && (
          <option value="" disabled>
            Select Store
          </option>
        )}
        {companies.map((company) => (
          <optgroup key={company.id} label={company.name}>
            {company.stores.map((store) => (
              <option key={store.id} value={store.id}>
                {store.name}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    </div>
  );
};

export default SiteSelector;
