import React, { useContext, useEffect, useState } from "react";
import "./SPLH.css";
import {
  db,
  doc,
  getDoc,
  collection,
  query,
  where,
  getAggregateFromServer,
  sum,
} from "../../firebase";
import { SiteContext } from "../../context/SiteContext";
import { format, subDays } from "date-fns";
import { useSales } from "../../context/SalesContext";

const SPLH = () => {
  const { dailySales, previousWeekSales, loading: salesLoading } = useSales();
  const { selectedSite, selectedDate } = useContext(SiteContext);
  const [loading, setLoading] = useState(true);
  const [totalLabourCost, setTotalLabourCost] = useState(0);
  const [previousTotalLabourCost, setPreviousTotalLabourCost] = useState(0);
  const [SPLH, setSPLH] = useState(null);
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    const fetchWagesData = async (date) => {
      if (!selectedSite) {
        return 0;
      }

      try {
        const departmentsDocRef = doc(
          db,
          "stores",
          selectedSite,
          "IntegrationInfo",
          "PayrollIntegration",
          "Departments",
          "departments"
        );
        const departmentsDocSnap = await getDoc(departmentsDocRef);

        if (!departmentsDocSnap.exists()) {
          return 0;
        }

        const departments = departmentsDocSnap.data().departments;

        const coll = collection(db, "shifts");
        const q = query(
          coll,
          where("date", "==", date),
          where("department_id", "in", departments)
        );

        const sumAggregateQuery = getAggregateFromServer(q, {
          totalLabourCost: sum("shiftLengthInHours"),
        });

        const snapshot = await sumAggregateQuery;
        const data = snapshot.data();
        return data.totalLabourCost ?? 0;
      } catch (error) {
        console.error("Error fetching wages data:", error);
        return 0;
      }
    };

    const fetchData = async () => {
      setLoading(true);
      const currentDate = format(selectedDate, "yyyy-MM-dd");
      const previousDate = format(subDays(selectedDate, 7), "yyyy-MM-dd");

      const [currentLabourCost, previousLabourCost] = await Promise.all([
        fetchWagesData(currentDate),
        fetchWagesData(previousDate),
      ]);

      setTotalLabourCost(currentLabourCost);
      setPreviousTotalLabourCost(previousLabourCost);

      let currentSPLH = 0;
      if (currentLabourCost !== 0 && dailySales.amount !== 0) {
        currentSPLH = dailySales.amount / currentLabourCost;
        setSPLH(currentSPLH.toFixed(2));
      } else {
        setSPLH(0);
      }

      if (previousLabourCost !== 0 && previousWeekSales.amount !== 0) {
        const previousSPLH = previousWeekSales.amount / previousLabourCost;
        const diff = (currentSPLH - previousSPLH).toFixed(2);
        setDifference(diff);
      } else {
        setDifference(0);
      }

      setLoading(false);
    };

    fetchData();
  }, [selectedSite, selectedDate, dailySales.amount, previousWeekSales.amount]);

  if (loading || salesLoading) {
    return <div>Loading...</div>;
  }

  const differenceClass =
    difference >= 0 ? "difference-positive" : "difference-negative";

  return (
    <div className="wage-percentage-container">
      <div className="label">Sales per Labour Hour</div>
      <div className="amount">{SPLH !== null ? `$${SPLH}` : "$0.00"}</div>
      <div className={`difference ${differenceClass}`}>
        {difference >= 0 ? "+" : ""}${difference}
      </div>
    </div>
  );
};

export default SPLH;
