// src/context/SiteContext.js
import React, { createContext, useState, useEffect } from "react";

export const SiteContext = createContext();

export const SiteProvider = ({ children }) => {
  const [selectedSite, setSelectedSite] = useState(() => {
    const savedSite = localStorage.getItem("selectedSite");
    return savedSite ? JSON.parse(savedSite) : "";
  });

  const [selectedCompany, setSelectedCompany] = useState(() => {
    const savedCompany = localStorage.getItem("selectedCompany");
    return savedCompany ? JSON.parse(savedCompany) : "";
  });

  const [selectedTax, setSelectedTax] = useState(() => {
    const savedCompany = localStorage.getItem("selectedCompany");
    return savedCompany ? JSON.parse(savedCompany) : "";
  });

  // Initialize selectedDate without reading from localStorage
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Initialize selectedDateRange without reading from localStorage
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  useEffect(() => {
    localStorage.setItem("selectedSite", JSON.stringify(selectedSite));
  }, [selectedSite]);

  useEffect(() => {
    localStorage.setItem("selectedCompany", JSON.stringify(selectedCompany));
  }, [selectedCompany]);

  return (
    <SiteContext.Provider
      value={{
        selectedSite,
        setSelectedSite,
        selectedCompany,
        setSelectedCompany,
        selectedDate,
        setSelectedDate,
        selectedDateRange,
        setSelectedDateRange,
        selectedTax,
        setSelectedTax,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
