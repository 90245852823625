// src/integrations-page/integration-widgets/tanda-oauth/tanda-oauth.js
import React, { useContext, useState, useEffect } from "react";
import { SiteContext } from "../../../context/SiteContext";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import "../../integration-widgets/integration-tile.css";
import MappingModal from "./mapping-modal";

const TandaAuth = () => {
  const { selectedCompany } = useContext(SiteContext);
  const [isConnected, setIsConnected] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const checkConnectionStatus = async () => {
      if (selectedCompany) {
        const docRef = doc(
          db,
          "companies",
          selectedCompany,
          "IntegrationInfo",
          "TandaTokens"
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsConnected(docSnap.data().TandaConnected);
        }
      }
    };

    checkConnectionStatus();
  }, [selectedCompany]);

  const handleAuth = () => {
    const clientId =
      "862e8e844b4bf4b958699884c6ba2cf7fa44d8015a86af03ab38afd9ebb8dbb0";
    const scopes =
      "timesheet cost roster department me user organisation settings platform";
    const redirectUri =
      "https://australia-southeast1-hospo-sense.cloudfunctions.net/tandaOauth/callback";
    const authUrl = `https://my.tanda.co/api/oauth/authorize?scope=${scopes}&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&state=${selectedCompany}`;

    window.location.href = authUrl;
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="integration-tile">
      <div className="int-header">
        <img className="logo" src="/icons/tandalogo.png" alt="Logo" />
        <div className="title">Tanda</div>
      </div>
      <div className="description">
        #1 platform for onboarding, rosters, attendance and workforce success!
      </div>
      <div className="status">
        <div className="status-indicator">
          <div className={`ellipse ${isConnected ? "connected" : ""}`} />
          <div className="status-text">
            {isConnected ? "Connected" : "Not Connected"}
          </div>
        </div>
        {isConnected ? (
          <button className="connect-button" onClick={openModal}>
            Settings
          </button>
        ) : (
          <button className="connect-button" onClick={handleAuth}>
            Connect Now
          </button>
        )}
      </div>
      {modalOpen && <MappingModal onClose={closeModal} />}
    </div>
  );
};

export default TandaAuth;
