import React, { useState, useEffect, useContext, useCallback } from "react";
import { db, doc, getDoc, collection, query, where, getAggregateFromServer, sum } from "../../../firebase";
import { SiteContext } from "../../../context/SiteContext";
import { format, eachDayOfInterval } from "date-fns";
import "./PremiumTiles.css"

const PTiles = () => {
  const { selectedSite, selectedDateRange } = useContext(SiteContext);
  const [totalLabourCost, setTotalLabourCost] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [taxFilter, setTaxFilter] = useState(0);
  const [profits, setProfits] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchRangedLabourData = useCallback(async () => {
    const departmentsDocRef = doc(
      db,
      "stores",
      selectedSite,
      "IntegrationInfo",
      "PayrollIntegration",
      "Departments",
      "departments"
    );
    const departmentsDocSnap = await getDoc(departmentsDocRef);

    if (!departmentsDocSnap.exists()) {
      return 0;
    }

    const departments = departmentsDocSnap.data().departments;
    const startDate = format(selectedDateRange[0], "yyyy-MM-dd");
    const endDate = format(selectedDateRange[1], "yyyy-MM-dd");

    const coll = collection(db, "shifts");
    const q = query(
      coll,
      where("date", ">=", startDate),
      where("date", "<=", endDate),
      where("department_id", "in", departments)
    );

    const totalLabourCostQuery = getAggregateFromServer(q, {
      totalLabourCost: sum("cost"),
    });

    const snapshot = await totalLabourCostQuery;
    return snapshot.data().totalLabourCost ?? 0;
  }, [selectedSite, selectedDateRange]);

  const fetchRangedSalesData = useCallback(async (taxFilter) => {
    const startDate = selectedDateRange[0];
    const endDate = selectedDateRange[1];

    const dates = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    const salesData = await Promise.all(
      dates.map(async (date) => {
        const formattedDate = format(date, "dd-MM-yyyy");
        const docRef = doc(db, `stores/${selectedSite}/summary/${formattedDate}`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          return taxFilter
            ? docSnap.data().totalSales || 0
            : docSnap.data().totalSalesExTax || 0;
        } else {
          return 0;
        }
      })
    );

    return salesData.reduce((acc, curr) => acc + curr, 0);
  }, [selectedSite, selectedDateRange]);

  const fetchRangedExpenseData = useCallback(async () => {
    const accountingDocRef = doc(
      db,
      "stores",
      selectedSite,
      "IntegrationInfo",
      "XeroIntegration"
    );
    const accountingDocSnap = await getDoc(accountingDocRef);

    if (!accountingDocSnap.exists()) {
      return 0;
    }

    const tenant = accountingDocSnap.data().tenant_id;
    const startDate = format(selectedDateRange[0], "yyyy-MM-dd");
    const endDate = format(selectedDateRange[1], "yyyy-MM-dd");

    const coll = collection(db, "xero-invoices");
    const q = query(
      coll,
      where("invoiceDate", ">=", startDate),
      where("invoiceDate", "<=", endDate),
      where("tenantID", "==", tenant),
      where("type", "==", `ACCPAY`),
      where("status", "in", ["PAID", "AUTHORISED"])
    );

    const totalExpenseCostQuery = getAggregateFromServer(q, {
      totalExpenseCost: sum("total"),
    });

    const snapshot = await totalExpenseCostQuery;
    return snapshot.data().totalExpenseCost ?? 0;
  }, [selectedSite, selectedDateRange]);

  const getTaxFilter = useCallback(async () => {
    const taxFilterRef = await getDoc(doc(db, `stores/${selectedSite}`));
    return taxFilterRef.data()?.includeTax ?? 0;
  }, [selectedSite]);

  useEffect(() => {
    if (!selectedSite || !selectedDateRange) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const [labourData, salesData, expenseData, taxData] = await Promise.all([
          fetchRangedLabourData(),
          fetchRangedSalesData(taxFilter),
          fetchRangedExpenseData(),
          getTaxFilter(),
        ]);

        setTotalLabourCost(labourData);
        setTotalSales(salesData);
        setTotalExpenses(expenseData);
        setTaxFilter(taxData);

        const calculatedProfits = salesData - labourData - expenseData;
        setProfits(calculatedProfits);

      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchRangedLabourData, fetchRangedSalesData, fetchRangedExpenseData, getTaxFilter, selectedDateRange, selectedSite, taxFilter]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-tile-container">
      <div className="p-tile-content">
        <div className="p-tile-row-one">
          <div className="p-tile-cell">
          <div className="p-tile-text">
            <span>Sales</span>
            <span className="p-tile-value">{`$${totalSales.toFixed(2)}`}</span>
          </div>
          </div>
          <div className="p-tile-cell">
          <div className="p-tile-text">
            <span>Labour</span>
            <span className="p-tile-value">{`$${totalLabourCost.toFixed(2)}`}</span>
          </div>
          </div>
          <div className="p-tile-cell">
          <div className="p-tile-text">
            <span>Expenses</span>
            <span className="p-tile-value">{`$${totalExpenses.toFixed(2)}`}</span>
          </div>
          </div>
        </div>
        <div className="p-tile-row-two">
        <div className="p-tile-cell">
          <div className="p-tile-text">
            <span>Profits</span>
            <span className="p-tile-value">{`$${profits.toFixed(2)}`}</span>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default PTiles;
