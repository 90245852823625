import React, { useState } from "react";
import Navbar from "../../global-components/navbar/navbar.js";
import Hamburger from "hamburger-react";
import SiteSelector from "../../main-dash-components/SiteSelector/SiteSelector.js";
import DateRange from "../../global-components/other/DateRange/DateRange.jsx";
import SalesRange from "./PremiumTiles/PSalesGraph.jsx";
import PTiles from "./PremiumTiles/PTiles.jsx";
import LabourRange from "./PremiumTiles/PLabourGraph.jsx";
import ExpensesRange from "./PremiumTiles/PExpensesGraph.jsx";

const PremiumDashboard  = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
  
    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    return (
      <div className="main-container">
        <Navbar isMenuOpen={isMenuOpen} onMenuToggle={toggleMenu} />
        <main className="main-dashboard">
          <header className="header">
            <div className="selectors-row">
              <Hamburger toggled={isMenuOpen} toggle={toggleMenu} />
              <SiteSelector />
              <DateRange />
            </div>
          </header>
          <PTiles />
          <SalesRange />
          <LabourRange />
          <ExpensesRange />
        </main>
      </div>
    );
  };
  
  export default PremiumDashboard;
  