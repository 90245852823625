// src/routes.js
import React from "react";
import {
  HiChartBar,
  HiAdjustments,
  HiOutlineStar,
  HiCode,
  HiTemplate,
} from "react-icons/hi";

// Dynamic imports to avoid circular dependency issues
const Dashboard = React.lazy(() =>
  import("./main-dash-components/Dashboard/Dashboard")
);
const Settings = React.lazy(() =>
  import("./global-components/Settings/settings")
);
const LabourDashboard = React.lazy(() =>
  import("./premium-dashboards/LabourReport/LabourDashboard")
);
const PremiumDashboard = React.lazy(() =>
  import("./premium-dashboards/PremiumDashboard/PremiumDashboard")
);

const Routes = [
  {
    title: "Dashboard",
    href: "/dashboard",
    Icon: HiChartBar,
    Component: Dashboard,
  },
  {
    title: "Labour Dashboard",
    href: "/labour-dashboard",
    Icon: HiOutlineStar,
    Component: LabourDashboard,
  },
  {
    title: "Premium Dashboard",
    href: "/premium",
    Icon: HiTemplate,
    Component: PremiumDashboard,
  },
  {
    title: "Integrations",
    href: "/integrations",
    Icon: HiCode,
    Component: Dashboard,
  },
  {
    title: "Settings",
    href: "/settings",
    Icon: HiAdjustments,
    Component: Settings,
  },
];

export default Routes;
