/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { db } from "../../../firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { SiteContext } from "../../../context/SiteContext";
import "./mapping-modal.css";

const MappingModal = ({ onClose }) => {
  const { selectedCompany } = useContext(SiteContext);
  const [sites, setSites] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeMappings, setStoreMappings] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const [endpoint, setEndpoint] = useState("");

  useEffect(() => {
    const fetchSitesAndStores = async () => {
      if (selectedCompany) {
        try {
          // Fetch Deputy Endpoint
          const deputyEndpoint = doc(
            db,
            "companies",
            selectedCompany,
            "IntegrationInfo",
            "DeputyInfo"
          );
          const endpointSnap = await getDoc(deputyEndpoint);
          if (endpointSnap.exists()) {
            const deputyData = endpointSnap.data();
            setIsConnected(deputyData.DeputyConnected);
            const endpoint = deputyData.endpoint;
            setEndpoint(endpoint);

            // Fetch Deputy Sites if endpoint is available
            if (endpoint) {
              const sitesData = [];
              // Fetch all subcollections under the endpoint document
              const subcollectionsSnap = await getDocs(
                collection(db, `deputyData/${endpoint}/locations`)
              );
              subcollectionsSnap.forEach((doc) => {
                const data = doc.data();
                sitesData.push({
                  id: data.Id,
                  name: data.CompanyName,
                });
              });
              setSites(sitesData);
            }
          }

          // Fetch Stores and their current mappings
          const storesSnapshot = await getDocs(
            collection(db, "companies", selectedCompany, "Stores")
          );
          const storesData = storesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Set initial store mappings based on current `department_ids`
          const initialMappings = {};
          storesData.forEach((store) => {
            initialMappings[store.id] = store.department_ids || [];
          });
          setStoreMappings(initialMappings);
          setStores(storesData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchSitesAndStores();
  }, [selectedCompany]);

  const handleCheckboxChange = (storeId, siteId) => {
    // Update store mappings when checkboxes are toggled
    setStoreMappings((prevState) => {
      const storeMapping = prevState[storeId] || [];
      if (storeMapping.includes(siteId)) {
        return {
          ...prevState,
          [storeId]: storeMapping.filter((id) => id !== siteId),
        };
      } else {
        return {
          ...prevState,
          [storeId]: [...storeMapping, siteId],
        };
      }
    });
  };

  const handleSave = async () => {
    try {
      for (const [storeId, siteIds] of Object.entries(storeMappings)) {
        // Construct department IDs in the format endpoint.siteId
        const departmentIds = siteIds.map((siteId) => `${endpoint}.${siteId}`);

        const integrationPath = `/stores/${storeId}/IntegrationInfo/PayrollIntegration/Departments/departments`;
        const integrationDocRef = doc(db, integrationPath);
        await setDoc(integrationDocRef, {
          departments: departmentIds,
        });

        console.log(
          "Store ID: ",
          storeId,
          " || ",
          "Departments: ",
          departmentIds
        );

        fetch(
          "https://australia-southeast1-hospo-sense.cloudfunctions.net/labour-summary-backdate",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ storeId, departments: departmentIds }),
          }
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.success) {
              console.log(
                `Labor summary and breaks processed for store ${storeId}`
              );
            } else {
              console.error(
                `Failed to process labor summary and breaks for store ${storeId}`,
                result
              );
            }
          })
          .catch((error) => {
            console.error(
              `Error processing labor summary and breaks for store ${storeId}`,
              error
            );
          });
      }
      onClose();
    } catch (error) {
      console.error("Error saving mappings:", error);
    }
  };

  return (
    <div className="mm-modal-overlay">
      <div className="mm-modal-content">
        <div className="mm-header">
          <div className="mm-title">Deputy Connection Settings</div>
          <div className="mm-subtitle">
            Select which Deputy Sites are connected to your HospoSense Stores
          </div>
        </div>
        {isConnected ? (
          <div className="mapping-form">
            {stores.map((store) => (
              <div key={store.id} className="store-box">
                <div className="store-title">{store.name}</div>
                <div className="locations">
                  {sites.map((site) => (
                    <div key={site.id} className="location-item">
                      <span>{site.name}</span>
                      <label className="toggle-container">
                        <input
                          type="checkbox"
                          id={`${store.id}-${site.id}`}
                          checked={(storeMappings[store.id] || []).includes(
                            site.id
                          )}
                          onChange={() =>
                            handleCheckboxChange(store.id, site.id)
                          }
                        />
                        <div className="mm-toggle">
                          <div className="mm-rectangle"></div>
                          <div className="mm-ellipse"></div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="tm-button-group">
              <button className="tm-button delete-button">
                Delete Connection
              </button>
              <button className="tm-button reconnect-button">Reconnect</button>
              <button className="tm-button save-button" onClick={handleSave}>
                Save Settings
              </button>
            </div>
          </div>
        ) : (
          <div className="connect-message">Please connect to Deputy first.</div>
        )}
      </div>
    </div>
  );
};

export default MappingModal;
