import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth, googleProvider } from "../../firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleEmailLogin(e);
    }
  };

  return (
    <div className="login-container">
      <div className="login-page">
        <img
          className="hs-logo"
          src="/images/fullLogo.png"
          alt="HospoSense Logo"
        />
        <div className="SignInYourAccount">Sign in your account</div>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleEmailLogin}>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="ex: jon.smith@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="*********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
          <button type="submit" className="signIn-btn">
            SIGN IN
          </button>
        </form>
        <div className="orSignInWith">or sign in with</div>
        <div className="social-login">
          <button onClick={handleGoogleLogin} className="social-btn google-btn">
            <img
              src="/images/google-logo.png"
              alt="Google Logo"
              className="social-logo"
            />
          </button>
        </div>
        <div className="signup-link">
          Don’t have an account? <Link to="/signup">SIGN UP</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
