import React from 'react';
import './ReportSettings.css';

const ReportSettings = ({ settings, setSettings }) => {
  const handleTaxChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      includeTax: value === 'Inclusive of GST'
    }));
  };

  const handleSuperChange = (e) => {
    const value = e.target.value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      superannuation: parseFloat(value) / 100 // Convert percentage to number
    }));
  };

  const formatSuperannuation = (value) => {
    return (value * 100).toFixed(2); // Format number to percentage
  };

  return (
    <div className="report-settings">
      <div className="settings-label">Report Settings</div>
      <div className="rep-set-content">
        <div className="rep-set-list">
          <label>Tax Calculation:</label>
          <label>Superannuation:</label>
        </div>
        <div className="rep-set-inputs">
          <select
            name="taxCalculation"
            value={settings.includeTax ? 'Inclusive of GST' : 'Exclusive of GST'}
            onChange={handleTaxChange}
          >
            <option value="Inclusive of GST">Inclusive of GST</option>
            <option value="Exclusive of GST">Exclusive of GST</option>
          </select>
          <div className="input-wrapper">
            <input
              type="number"
              name="superannuation"
              value={formatSuperannuation(settings.superannuation)}
              onChange={handleSuperChange}
              step="0.01"
              min="0"
            />
            <span className="percentage-symbol">%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportSettings;
