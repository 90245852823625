// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signOut as firebaseSignOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  getDoc,
  doc,
  getDocs,
  query,
  where,
  AggregateField,
  AggregateQuerySnapshot,
  sum,
  getAggregateFromServer,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDWWUhJrBDNKWat_jYFoaLxz1BjcJA3Xhk",
  authDomain: "hospo-sense.firebaseapp.com",
  databaseURL:
    "https://hospo-sense-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hospo-sense",
  storageBucket: "hospo-sense.appspot.com",
  messagingSenderId: "898960072938",
  appId: "1:898960072938:web:039534da511f4d167d7793",
  measurementId: "G-P4NK055RJ3",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const signOut = async () => {
  await firebaseSignOut(auth);
};

export {
  auth,
  db,
  googleProvider,
  collection,
  getDoc,
  doc,
  getDocs,
  query,
  where,
  AggregateField,
  AggregateQuerySnapshot,
  sum,
  getAggregateFromServer,
  signOut,
};
