import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../../firebase";
import { collection, addDoc, doc, setDoc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { useAuth } from "../../../../context/AuthContext";
import "./AddStoreModal.css";

const AddStoreModal = ({ onClose }) => {
  const [locationName, setLocationName] = useState("");
  const [timezone, setTimezone] = useState("");
  const [startOfDay, setStartOfDay] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [timezones, setTimezones] = useState([]);
  const [hours, setHours] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserCompanies = async () => {
      if (user) {
        const userDocRef = doc(db, "profiles", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userCompanies = userDoc.data().companies || [];
          let companyData = [];
          for (let companyId of userCompanies) {
            const companyDocRef = doc(db, "companies", companyId);
            const companyDoc = await getDoc(companyDocRef);
            if (companyDoc.exists()) {
              companyData.push({ id: companyId, ...companyDoc.data() });
            }
          }
          setCompanies(companyData);
        }
      }
    };

    fetchUserCompanies();

    const australianTimezones = [
      "Australia/Sydney",
      "Australia/Melbourne",
      "Australia/Brisbane",
      "Australia/Adelaide",
      "Australia/Perth",
      "Australia/Darwin",
      "Australia/Hobart"
    ];
    setTimezones(australianTimezones);

    const hoursOfDay = Array.from({ length: 24 }, (_, i) => {
      const hour = i < 10 ? `0${i}:00` : `${i}:00`;
      return hour;
    });
    setHours(hoursOfDay);
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCompany) {
      console.error("No company selected");
      return;
    }
    try {
      const storeDocRef = await addDoc(collection(db, "stores"), {
        name: locationName,
        company: selectedCompany,
        users: [user.uid],
        timezone: timezone,
        startOfDay: startOfDay,
        referral_code: referralCode
      });

      await setDoc(doc(db, `companies/${selectedCompany}/Stores/${storeDocRef.id}`), {
        name: locationName
      });

      const userDocRef = doc(db, "profiles", user.uid);
      await updateDoc(userDocRef, {
        stores: arrayUnion(storeDocRef.id)
      });

      navigate("/dashboard");
    } catch (error) {
      console.error("Error adding store: ", error);
    }
  };

  return (
    <div className="ab-modal-backdrop" onClick={onClose}>
      <div className="ab-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="ab-title">Add a new store</div>
        <form onSubmit={handleSubmit}>
          <div className="ab-form-group">
            <label>Company</label>
            <select
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
              required
            >
              <option value="" disabled>Select Company</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>
          <div className="ab-form-group">
            <label>Location Name</label>
            <input
              type="text"
              placeholder="ex: BB Springfield"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
              required
            />
          </div>
          <div className="ab-form-group">
            <label>Timezone</label>
            <select
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
              required
            >
              <option value="" disabled>Select your timezone</option>
              {timezones.map((tz) => (
                <option key={tz} value={tz}>{tz}</option>
              ))}
            </select>
          </div>
          <div className="ab-form-group">
            <label>Start of Day</label>
            <select
              value={startOfDay}
              onChange={(e) => setStartOfDay(e.target.value)}
              required
            >
              <option value="" disabled>Select start of day</option>
              {hours.map((hour) => (
                <option key={hour} value={hour}>{hour}</option>
              ))}
            </select>
          </div>
          <div className="ab-form-group">
            <label>Referral Code</label>
            <input
              type="text"
              placeholder="optional"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
          </div>
          <div className="ab-button-group">
            <button type="button" className="ab-modal-close" onClick={onClose}>
              Close
            </button>
            <button type="submit" className="ab-submit-button">
              ADD STORE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddStoreModal;
