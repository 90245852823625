// LabourCost.jsx
import React from "react";
import { useData } from "../../../../context/LabourDataContext";
import "./LabourStatsTiles.css";

const LabourCostTile = () => {
  const { labourCostTotal } = useData();

  return (
    <div className="labour-tile">
      <h3>Actual Cost</h3>
      <p>{labourCostTotal !== null ? `$${labourCostTotal.toFixed(2)}` : "Loading..."}</p>
    </div>
  );
};

export default LabourCostTile;
