import React, { useContext, useState, useEffect } from "react";
import { SiteContext } from "../../../context/SiteContext";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import "../../integration-widgets/integration-tile.css";
import MappingModal from "./mapping-modal";

const KountaAuth = () => {
  const { selectedCompany } = useContext(SiteContext);
  const [isConnected, setIsConnected] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const checkConnectionStatus = async () => {
      if (selectedCompany) {
        const docRef = doc(
          db,
          "companies",
          selectedCompany,
          "IntegrationInfo",
          "KountaInfo"
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsConnected(docSnap.data().KountaConnected);
          setIsSyncing(docSnap.data().KountaSyncing);
        }
      }
    };

    checkConnectionStatus();
  }, [selectedCompany]);

  const title = "Lightspeed O-Series";
  const description =
    "Lightspeed Restaurant O-Series: Comprehensive Point of Sale Solution";

  const handleAuth = () => {
    const clientId = "VHFzv48voWRINwt0";
    const redirectUri =
      "https://australia-southeast1-hospo-sense.cloudfunctions.net/lightspeedOauth/callback"; // Your Firebase hosting URL
    const authUrl = `https://my.kounta.com/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_type=code&state=${selectedCompany}`;

    window.location.href = authUrl;
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="integration-tile">
      <div className="int-header">
        <img className="logo" src="/icons/lsoLogo.png" alt="Logo" />
        <div className="title">{title}</div>
      </div>
      <div className="description">{description}</div>
      <div className="status">
        <div className="status-indicator">
          <div className={`ellipse ${isConnected ? "connected" : ""}`} />
          <div className="status-text">
            {isConnected ? "Connected" : "Not Connected"}
          </div>
        </div>
        {isSyncing && isConnected ? (
          <button className="connect-button" disabled>
            Syncing
          </button>
        ) : isConnected ? (
          <button className="connect-button" onClick={openModal}>
            Settings
          </button>
        ) : (
          <button className="connect-button" onClick={handleAuth}>
            Connect Now
          </button>
        )}
      </div>
      {modalOpen && <MappingModal onClose={closeModal} />}
    </div>
  );
};

export default KountaAuth;
