// src/global-components/navbar/navbar.js
import React from "react";
import { Link } from "react-router-dom";
import Routes from "../../routes";
import "./navbar.css"; // Import CSS for Navbar
import { signOut } from "../../firebase"; // Import the signOut function from firebase.js

const Navbar = ({ isMenuOpen, onMenuToggle, onLogout, storeName }) => {
  const handleLogout = async () => {
    try {
      await signOut(); // Sign out using Firebase
      localStorage.clear(); // Clear all items in local storage
      window.location.href = "/login"; // Redirect to login page
    } catch (error) {
      console.error("Error logging out:", error);
      // Handle errors appropriately (e.g., display an error message)
    }
  };

  return (
    <nav className={`navbar ${isMenuOpen ? "open" : ""}`}>
      <div className="navbar-image">
        <img src={process.env.PUBLIC_URL + "/images/fullLogo.png"} alt="" />
      </div>
      <ul>
        {Routes.map((route, index) => (
          <li key={index}>
            <Link to={route.href} onClick={onMenuToggle}>
              <span className="navbar-icon">
                {" "}
                {/* Wrap icon here */}
                <route.Icon />
              </span>
              {route.title}
            </Link>
          </li>
        ))}
      </ul>
      {isMenuOpen && (
        <button className="close-btn" onClick={onMenuToggle}>
          <span aria-label="Close">&#10006;</span>
        </button>
      )}
      <button className="logout-btn" onClick={handleLogout}>
        Logout
      </button>
    </nav>
  );
};

export default Navbar;
