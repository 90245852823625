/* eslint-disable no-unused-vars */
// src/components/WeeklySales/WeeklySales.js
import React, { useState, useEffect, useContext } from "react";
import { db, doc, getDoc } from "../../firebase";
import { SiteContext } from "../../context/SiteContext";
import { Bar } from "react-chartjs-2";
import { format } from "date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./WeeklySales.css";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const WeeklySales = () => {
  const { selectedSite, selectedDate } = useContext(SiteContext);
  const [weeklySales, setWeeklySales] = useState({
    totalAmount: 0,
    data: [],
  });
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const fetchWeeklySalesData = async () => {
      if (!selectedSite || !selectedDate) {
        setLoading(false);
        return;
      }
      try {
        const startDate = selectedDate;
        const startOfWeek = new Date(startDate);
        startOfWeek.setDate(
          startDate.getDate() - ((startDate.getDay() + 6) % 7)
        ); // Get the last Monday
        const salesData = [];
        let totalWeeklySales = 0;

        for (let i = 0; i < 7; i++) {
          const currentDate = new Date(startOfWeek);
          currentDate.setDate(startOfWeek.getDate() + i);

          // Format date as DD-MM-YYYY
          const day = String(currentDate.getDate()).padStart(2, "0");
          const month = String(currentDate.getMonth() + 1).padStart(2, "0");
          const year = currentDate.getFullYear();
          const dateString = `${day}-${month}-${year}`;

          const dailySalesDocRef = doc(
            db,
            "stores",
            selectedSite,
            "summary",
            dateString
          );
          const dailySalesDocSnap = await getDoc(dailySalesDocRef);

          if (dailySalesDocSnap.exists) {
            const dailySalesData = dailySalesDocSnap.data();
            const dailySales = dailySalesData?.totalSales ?? 0; // Ensure totalSales exists or set to 0
            salesData.push({
              day: currentDate.toLocaleString("en-us", { weekday: "short" }),
              amount: dailySales,
            });
            totalWeeklySales += dailySales;
          } else {
            salesData.push({
              day: currentDate.toLocaleString("en-us", { weekday: "short" }),
              amount: 0,
            });
          }
        }

        setWeeklySales({
          totalAmount: totalWeeklySales,
          data: salesData,
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching weekly sales data:", error);
        setLoading(false);
      }
    };

    fetchWeeklySalesData();
  }, [selectedSite, selectedDate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = {
    labels: weeklySales.data.map((item) => item.day),
    datasets: [
      {
        label: "Sales",
        data: weeklySales.data.map((item) => item.amount.toFixed(2)),
        backgroundColor: "#00a6fb",
        borderRadius: 10,
      },
    ],
  };

  const getMaxValueWithPadding = (data) => {
    if (!data) return 0;
    const maxVal = Math.max(...data);
    return Math.ceil(maxVal / 100) * 100;
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          maxTicksLimit: 5,
        },
        grid: {
          display: false, // Hide y-axis gridlines
        },
        max: getMaxValueWithPadding(data.datasets[0].data),
      },
      x: {
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      responsive: true,
    },
  };

  return (
    <div className="graph-container">
      <div className="graph-label">Weekly Sales</div>
      <div className="graph-amount">${weeklySales.totalAmount.toFixed(2)}</div>
      <div className="sbs-graph">
        <Bar
          data={data}
          options={options}
          width={200}
          height={isMobile ? undefined : 80}
        />
      </div>
    </div>
  );
};

export default WeeklySales;
