// src/premium-dashboards/LabourDashboard.js
import React, { useState } from "react";
import "../../main-dash-components/Dashboard/Dashboard.css";
import { Sling as Hamburger } from "hamburger-react";
import Navbar from "../../global-components/navbar/navbar.js";
import SiteSelector from "../../main-dash-components/SiteSelector/SiteSelector.js";
import DateSelector from "../../main-dash-components/DatePicker/DateSelector.js";
import LabourUsageGraph from "./LabourCostGraph/LabourCostGraph.js";
import LabourStats from "./LabourStats/LabourStats.jsx";
import ShiftsTable from "./TimesheetTable/TimesheetTable.jsx";
import "./LabourDashboard.css";

const LabourDashboard = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className="main-container">
      <Navbar isMenuOpen={isMenuOpen} onMenuToggle={toggleMenu} />
      <main className="main-dashboard">
        <header className="header">
          <div className="selectors-row">
            <Hamburger toggled={isMenuOpen} toggle={toggleMenu} />
            <SiteSelector />
            <DateSelector />
          </div>
        </header>
        <LabourStats />
        <LabourUsageGraph />
        <ShiftsTable />
      </main>
    </div>
  );
};

export default LabourDashboard;
