import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import Dashboard from "./main-dash-components/Dashboard/Dashboard";
import Login from "./main-dash-components/Login/Login";
import Signup from "./signUpPage/signUp";
import SetupCompany from "./companyCreate/SetupCompany";
import GlobalStyles from "./GlobalStyles";
import { SiteProvider } from "./context/SiteContext";
import { SalesProvider } from "./context/SalesContext";
import { DataProvider } from "./context/LabourDataContext";
import Settings from "./global-components/Settings/settings";
import Navbar from "./global-components/navbar/navbar";
import LabourDashboard from "./premium-dashboards/LabourReport/LabourDashboard";
import Integrations from "./integrations-page/integrations-page";
import PrivateRoute from "./PrivateRoute";
import OAuthCallback from "./OAuthCallback";
import LSOOAuth from "./integrations-page/integration-widgets/lso-oauth/lso-oauth";
import MultiSiteDashboard from "./multiStoreDashboard/multiStoreDashboard";
import PremiumDashboard from "./premium-dashboards/PremiumDashboard/PremiumDashboard";

const AuthenticatedApp = () => (
  <div>
    <Navbar />
    <Routes>
      <Route
        path="/dashboard"
        element={<PrivateRoute requiredPermissions={[]} />}
      >
        <Route path="" element={<Dashboard />} />
      </Route>
      <Route path="/labour-dashboard">
        <Route path="" element={<LabourDashboard />} />
      </Route>
      <Route
        path="/settings"
        element={<PrivateRoute requiredPermissions={[]} />}
      >
        <Route path="" element={<Settings />} />
      </Route>
      <Route path="/integrations">
        <Route path="" element={<Integrations />} />
      </Route>
      <Route
        path="/multisite"
        element={<PrivateRoute requiredPermissions={[]} />}
      >
        <Route path="" element={<MultiSiteDashboard />} />
      </Route>
      <Route
        path="/premium"
        element={<PrivateRoute requiredPermissions={[]} />}
      >
        <Route path="" element={<PremiumDashboard />} />
      </Route>
      <Route path="/callback" element={<OAuthCallback />} />
    </Routes>
  </div>
);

const App = () => {
  const { user, loading } = useAuth();

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <GlobalStyles />
      <SiteProvider>
        <SalesProvider>
          <DataProvider>
            <Router>
              <Routes>
                <Route
                  path="/"
                  element={user ? <Navigate to="/multisite" /> : <Login />}
                />
                <Route
                  path="/*"
                  element={user ? <AuthenticatedApp /> : <Navigate to="/" />}
                />
                <Route path="/signup" element={<Signup />} />
                <Route path="/companyCreate" element={<SetupCompany />} />
                <Route path="/callback" element={<OAuthCallback />} />
                <Route path="/oauth" element={<LSOOAuth />} />
              </Routes>
            </Router>
          </DataProvider>
        </SalesProvider>
      </SiteProvider>
    </>
  );
};

const ExportedApp = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default ExportedApp;
