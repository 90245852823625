import React, { useState, useEffect, useContext, useMemo } from "react";
import { db, collection, query, getDoc, doc, where, getAggregateFromServer, sum } from "../../../firebase";
import { SiteContext } from "../../../context/SiteContext";
import { format, eachDayOfInterval } from "date-fns";
import { Bar } from "react-chartjs-2";
import "./PremiumGraph.css";

const ExpensesRange = () => {
  const { selectedSite, selectedDateRange } = useContext(SiteContext);
  const [rangedExpenses, setRangedExpenses] = useState({
    totalAmount: 0,
    data: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRangedExpensesData = async () => {
      if (!selectedSite || !selectedDateRange ) {
        setLoading(false);
        return;
      }

      try {
        const startDate = selectedDateRange[0];
        const endDate = selectedDateRange[1];

        const dates = eachDayOfInterval({
          start: startDate,
          end: endDate,
        });

        const expensesData = [];
        const accountingDocRef = doc(
          db,
          "stores",
          selectedSite,
          "IntegrationInfo",
          "XeroIntegration"
        );
        const accountingDocSnap = await getDoc(accountingDocRef);

        if (!accountingDocSnap.exists()) {
          return 0;
        }
    
        const tenant = accountingDocSnap.data().tenant_id;

        for (let date of dates) {
          const theDate = format(new Date(date), "yyyy-MM-dd");
          const reportDate = format(date, "dd-MMM");
          const coll = collection(db, "xero-invoices");
          const q = query(
            coll,
            where("invoiceDate", "==", theDate),
            where("tenantID", "==", tenant),
            where("type", "==", `ACCPAY`),
            where("status", "in", ["PAID", "AUTHORISED"])
                );

          const costAggQuery = await getAggregateFromServer(q, {
            totalexpensesCost: sum("total"),
          });

          const expenses = costAggQuery.data()?.totalexpensesCost || 0;
          expensesData.push({ date: reportDate, expenses });
        }

        const totalAmount = expensesData.reduce((acc, curr) => acc + curr.expenses, 0);

        setRangedExpenses({
          totalAmount,
          data: expensesData,
        });

      } catch (error) {
        console.error("Error fetching expenses data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRangedExpensesData();
  }, [selectedSite, selectedDateRange]);

  const chartData = useMemo(() => ({
    labels: rangedExpenses.data.map((item) => item.date),
    datasets: [
      {
        label: "Expenses",
        data: rangedExpenses.data.map((item) => item.expenses),
        backgroundColor: "#00a6fb",
        borderRadius: 10,
      },
    ],
  }), [rangedExpenses]);

  const options = useMemo(() => ({
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          maxTicksLimit: 5,
        },
      },
      x: {
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
  }), []);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="ab-PremiumGraph">
          <Bar data={chartData} options={options} />
        </div>
      )}
    </div>
  );
};

export default ExpensesRange;
