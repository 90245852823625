import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db, googleProvider } from "../firebase";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import "./signUp.css";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Create user document in Firestore
      await setDoc(doc(db, "profiles", user.uid), {
        name: name,
        email: email
      });

      // Redirect to /companyCreate
      navigate("/companyCreate");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setError("This email is already associated with an account.");
      } else {
        setError(error.message);
      }
    }
  };

  const handleProviderSignup = async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user document already exists
      const userDoc = await getDoc(doc(db, "profiles", user.uid));
      if (userDoc.exists()) {
        setError("This email is already associated with an account.");
        return;
      }

      // Create user document in Firestore if it doesn't exist
      await setDoc(doc(db, "profiles", user.uid), {
        name: user.displayName,
        email: user.email
      });

      // Redirect to /companyCreate
      navigate("/companyCreate");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignup = () => handleProviderSignup(googleProvider);

  return (
    <div className="signup-container">
      <div className="signup-page">
        <img
          className="hs-logo"
          src="/images/fullLogo.png"
          alt="HospoSense Logo"
        />
        <div className="CreateYourAccount">Create your account</div>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSignup}>
          <div className="input-group">
            <label>Name</label>
            <input
              type="text"
              placeholder="ex: jon smith"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="ex: jon.smith@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="*********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Confirm password</label>
            <input
              type="password"
              placeholder="*********"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="signup-btn">
            SIGN UP
          </button>
        </form>
        <div className="signin-link">
          Have an account? <Link to="/signin">SIGN IN</Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
