/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from 'react';
import { FaCalendarAlt } from "react-icons/fa";
import { DateRangePicker, VisuallyHidden } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import { SiteContext } from "../../../context/SiteContext";
import 'rsuite/dist/rsuite.min.css';
import "./DateRange.css";

const predefinedRanges = [
  { label: 'Today', value: [new Date(), new Date()], placement: 'left' },
  { label: 'Yesterday', value: [addDays(new Date(), -1), addDays(new Date(), -1)], placement: 'left' },
  { label: 'This week', value: [startOfWeek(new Date()), endOfWeek(new Date())], placement: 'left' },
  { label: 'Last week', value: [startOfWeek(addWeeks(new Date(), -1)), endOfWeek(addWeeks(new Date(), -1))], placement: 'left' },
  { label: 'This month', value: [startOfMonth(new Date()), new Date()], placement: 'left' },
  { label: 'Last month', value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))], placement: 'left' },
];

const DateRange = () => {
  const { selectedDateRange, setSelectedDateRange } = useContext(SiteContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!selectedDateRange) {
      const now = new Date();
      setSelectedDateRange([now, now]);
    }
  }, [selectedDateRange, setSelectedDateRange]);

  const handleIconClick = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleDateChange = (date) => {
    setSelectedDateRange(date);
  };

  return (
    <div className="date-selector">
      <FaCalendarAlt className="calendar-icon" onClick={handleIconClick} />
      <VisuallyHidden><DateRangePicker
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={handleClose}
        value={selectedDateRange}
        onChange={handleDateChange}
        showOneCalendar
        appearance="subtle"
        ranges={predefinedRanges}
        placeholder="Select Date Range"
        format="yyyy-MM-dd"
        oneTap={false}
        cleanable={false}
        placement="auto"
      />
      {isOpen && (
        <div className="footer-buttons">
          <button onClick={handleClose} className="cancel-button">
            Cancel
          </button>
          <button onClick={handleClose} className="ok-button">
            OK
          </button>
        </div>
      )}
      </VisuallyHidden>
    </div>
  );
};

export default DateRange;
