/* eslint-disable no-unused-vars */
// src/components/DateSelector/DateSelector.js
import React, { useState, useRef, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import "./DateSelector.css";
import { SiteContext } from "../../context/SiteContext";
import { toZonedTime } from "date-fns-tz";

const DateSelector = () => {
  const { selectedDate, setSelectedDate } = useContext(SiteContext);
  const [isOpen, setIsOpen] = useState(false);
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (!selectedDate) {
      const now = new Date();
      const timeZone = "Australia/Brisbane";
      const zonedDate = toZonedTime(now, timeZone);
      setSelectedDate(zonedDate);
    }
  }, [selectedDate, setSelectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false); // Close the modal on date selection
  };

  const handleIconClick = () => {
    setIsOpen(true); // Open the modal on icon click
  };

  const handleClose = () => {
    setIsOpen(false); // Close the modal
  };

  return (
    <>
      <div className="date-selector">
        <FaCalendarAlt className="calendar-icon" onClick={handleIconClick} />
      </div>

      {isOpen && (
        <div className="dp-modal">
          <div className="dp-modal-content">
            <button className="dp-close-button" onClick={handleClose}>
              &times;
            </button>
            <DatePicker
              ref={datepickerRef}
              selected={selectedDate}
              onChange={handleDateChange}
              shouldCloseOnSelect={true}
              dateFormat="dd/MM/yyyy"
              inline
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DateSelector;
