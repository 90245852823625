import React, { useState, useEffect, useContext, useMemo } from "react";
import { db, doc, getDoc } from "../../firebase";
import { SiteContext } from "../../context/SiteContext";
import { format, subDays } from "date-fns";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./SalesBreakdown.css";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const SalesBreakdown = () => {
  const { selectedSite, selectedDate } = useContext(SiteContext);
  const [dailySales, setDailySales] = useState({ amount: 0, data: [] });
  const [previousWeekSales, setPreviousWeekSales] = useState({
    amount: 0,
    data: [],
  });
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const defaultState = useMemo(
    () => ({
      amount: 0,
      data: Array(24).fill({ hour: 0, amount: 0 }),
    }),
    []
  );

  useEffect(() => {
    const fetchSalesData = async (date) => {
      if (!selectedSite) {
        return defaultState;
      }

      try {
        const summaryDocRef = doc(db, "stores", selectedSite, "summary", date);
        const [summaryDocSnap, hourlySalesDocSnap, startOfDayDocSnap] =
          await Promise.all([
            getDoc(summaryDocRef),
            getDoc(
              doc(
                db,
                "stores",
                selectedSite,
                "summary",
                date,
                "Hours",
                "hourlySales"
              )
            ),
            getDoc(doc(db, "stores", selectedSite)),
          ]);

        if (!summaryDocSnap.exists()) {
          return defaultState;
        }

        const totalSales = summaryDocSnap.data()?.totalSales ?? 0;
        const hourlySalesData = hourlySalesDocSnap.exists()
          ? hourlySalesDocSnap.data()
          : {};
        const startOfDayValue = startOfDayDocSnap.exists()
          ? startOfDayDocSnap.data().startOfDay
          : "00";

        const processedHourlySalesData = Object.entries(hourlySalesData).map(
          ([hour, amount]) => ({
            hour: parseInt(hour, 10),
            amount,
          })
        );

        const sortedData = sortByStartOfDay(
          processedHourlySalesData,
          parseInt(startOfDayValue, 10)
        );

        return {
          amount: totalSales,
          data:
            sortedData.length > 0
              ? sortedData
              : Array(24).fill({ hour: 0, amount: 0 }),
        };
      } catch (error) {
        console.error("Error fetching sales data:", error);
        return defaultState;
      }
    };

    const fetchData = async () => {
      setLoading(true);
      const currentDate = format(selectedDate, "dd-MM-yyyy");
      const previousDate = format(subDays(selectedDate, 7), "dd-MM-yyyy");

      const [currentSales, previousSales] = await Promise.all([
        fetchSalesData(currentDate),
        fetchSalesData(previousDate),
      ]);

      setDailySales(currentSales);
      setPreviousWeekSales(previousSales);
      setLoading(false);
    };

    fetchData();
  }, [selectedSite, selectedDate, defaultState]);

  const sortByStartOfDay = (data, startOfDay) => {
    data.sort((a, b) => a.hour - b.hour);
    const splitIndex = data.findIndex((item) => item.hour >= startOfDay);
    if (splitIndex === -1) return data;
    return [...data.slice(splitIndex), ...data.slice(0, splitIndex)];
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const data = {
    labels: dailySales.data.map((item) => item.hour),
    datasets: [
      {
        type: "line",
        label: "Last Week Sales",
        data: previousWeekSales.data.map((item) => item.amount.toFixed(2)),
        borderColor: "#FF3333",
        borderWidth: 2,
        fill: false,
      },
      {
        type: "bar",
        label: "Sales",
        data: dailySales.data.map((item) => item.amount.toFixed(2)),
        backgroundColor: "#00a6fb",
        borderRadius: 10,
      },
    ],
  };

  const getMaxValueWithPadding = (data) => {
    if (!data) return 0;
    const maxVal = Math.max(...data);
    return Math.ceil(maxVal / 100) * 100;
  };

  const allDataValues = [
    ...dailySales.data.map((item) => item.amount),
    ...previousWeekSales.data.map((item) => item.amount),
  ];

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          maxTicksLimit: 5,
        },
        grid: {
          display: false, // Hide y-axis gridlines
        },
        max: getMaxValueWithPadding(allDataValues),
      },
      x: {
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      maintainAspectRatio: false,
      responsive: true,
    },
  };

  const difference = (dailySales.amount - previousWeekSales.amount).toFixed(2);
  const differenceClass =
    difference >= 0 ? "difference-positive" : "difference-negative";

  return (
    <div className="graph-container">
      <div className="graph-label">Sales Breakdown</div>
      <div className="graph-amount">${dailySales.amount.toFixed(2)}</div>
      <div className={`difference ${differenceClass}`}>
        {difference >= 0 ? "+" : ""}${difference}
      </div>
      <div className="sb-graph">
        <Bar
          data={data}
          options={options}
          width={200}
          height={isMobile ? undefined : 80}
        />
      </div>
    </div>
  );
};

export default SalesBreakdown;
