import React, { useState } from "react";
import "./Dashboard.css";
import { Sling as Hamburger } from "hamburger-react";
import SalesBreakdown from "../SalesBreakdown/SalesBreakdown";
import WeeklySales from "../WeeklySales/WeeklySales";
import SiteSelector from "../SiteSelector/SiteSelector";
import DateSelector from "../DatePicker/DateSelector";
import WagePercentage from "../WagePercentage/WagePercentage";
import SPLH from "../SPLH/SPLH";
import ATV from "../ATV/ATV";
import Navbar from "../../global-components/navbar/navbar";
import WeeklyWagePercentage from "../WeeklyWagePercentage/WeeklyWagePercentage";
import WeeklyATVValue from "../WeeklyATV/WeeklyATV";

const Dashboard = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className="main-container">
      <Navbar isMenuOpen={isMenuOpen} onMenuToggle={toggleMenu} />
      <div className="main-dashboard">
        <div className="header">
          <div className="selectors-row">
            <Hamburger toggled={isMenuOpen} toggle={toggleMenu} />
            <SiteSelector />
            <DateSelector />
          </div>
        </div>
        <div className="line" />
        <div className="content-container">
          <div className="upper-dashboard">
            <SalesBreakdown className="grid-span-2" />
            <div className="data-column">
              <WagePercentage />
              <SPLH />
              <ATV />
            </div>
          </div>
          <div className="bottom-container">
            <div className="data-column">
              <WeeklyWagePercentage />
              <WeeklyATVValue />
            </div>
            <WeeklySales className="grid-span-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
