import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext"; // Ensure you have an Auth context to get the current user
import "./SetupCompany.css";

const SetupCompany = () => {
  const [companyName, setCompanyName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [timezone, setTimezone] = useState("");
  const [startOfDay, setStartOfDay] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [timezones, setTimezones] = useState([]);
  const [hours, setHours] = useState([]);

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const australianTimezones = [
      "Australia/Sydney",
      "Australia/Melbourne",
      "Australia/Brisbane",
      "Australia/Adelaide",
      "Australia/Perth",
      "Australia/Darwin",
      "Australia/Hobart",
    ];
    setTimezones(australianTimezones);

    const hoursOfDay = Array.from({ length: 24 }, (_, i) => {
      const hour = i < 10 ? `0${i}:00` : `${i}:00`;
      return hour;
    });
    setHours(hoursOfDay);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Create a new company document
      const companyDocRef = await addDoc(collection(db, "companies"), {
        name: companyName,
        referral_code: referralCode,
        users: [user.uid],
      });

      // Create a new store document
      const storeDocRef = await addDoc(collection(db, "stores"), {
        name: locationName,
        company: companyDocRef.id,
        users: [user.uid],
        timezone: timezone,
        startOfDay: startOfDay,
        referral_code: referralCode,
      });

      // Add the store reference to the company's Stores subcollection
      await setDoc(
        doc(db, "companies", companyDocRef.id, "Stores", storeDocRef.id),
        {
          name: locationName,
        }
      );

      // Retrieve the current user's profile document
      const userDocRef = doc(db, "profiles", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const updatedCompanies = userData.companies
          ? [...userData.companies, companyDocRef.id]
          : [companyDocRef.id];
        const updatedStores = userData.stores
          ? [...userData.stores, storeDocRef.id]
          : [storeDocRef.id];

        // Update the user's profile document with the new company and store
        await updateDoc(userDocRef, {
          companies: updatedCompanies,
          stores: updatedStores,
        });
      } else {
        // If the user profile document does not exist, create it
        await setDoc(userDocRef, {
          companies: [companyDocRef.id],
          stores: [storeDocRef.id],
        });
      }

      await fetch(
        "https://hooks.slack.com/services/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",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            blocks: [
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `New Customer signed up. Customer name: ${companyName}`,
                },
              },
            ],
          }),
        }
      );

      navigate("/dashboard");
    } catch (error) {
      console.error("Error creating company and store: ", error);
    }
  };

  return (
    <div className="setup-company-container">
      <div className="setup-company-page">
        <img
          className="hs-logo"
          src="/images/fullLogo.png"
          alt="HospoSense Logo"
        />
        <div className="SetupYourCompany">Setup your company</div>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Company Name</label>
            <input
              type="text"
              placeholder="ex: Bob's Burgers"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Location Name (just the first one)</label>
            <input
              type="text"
              placeholder="ex: Springfield"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Timezone</label>
            <select
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
              required
            >
              <option value="" disabled>
                Select your timezone
              </option>
              {timezones.map((tz) => (
                <option key={tz} value={tz}>
                  {tz}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Start of Day</label>
            <select
              value={startOfDay}
              onChange={(e) => setStartOfDay(e.target.value)}
              required
            >
              <option value="" disabled>
                Select start of day
              </option>
              {hours.map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Referral Code</label>
            <input
              type="text"
              placeholder="optional"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
          </div>
          <button type="submit" className="signup-btn">
            SIGN UP
          </button>
        </form>
      </div>
    </div>
  );
};

export default SetupCompany;
